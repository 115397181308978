/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/static-property-placement */
/* eslint-disable jsx-a11y/label-has-for,guard-for-in */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/sort-comp */
/* eslint max-len: ["error", { "code": 400 }] */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable arrow-parens */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable no-class-assign */
/* eslint-disable object-shorthand */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-confusing-arrow */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable prefer-template */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-else-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable  react/self-closing-comp */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable dot-notation */
/* eslint-disable lines-between-class-members */
/* eslint-disable object-curly-spacing */
/* eslint-disable max-len */

import React, { PureComponent } from 'react';
import {
  Button,
  ButtonToolbar,
  Card, CardBody, Col, Row,
} from 'reactstrap';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import AnswerAcceptRejectRadioSection from '../../../shared/components/generic-form/FormSections/SingleSections/AnswerAcceptRejectRadioSection';
import { scrollToFirstError } from '../../../shared/components/generic-form/submitFail';
import FormWithLoading from '../../../shared/components/form/FormWithLoading';

class AnswerVisitForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      returnedAnswersArray: [],
    };
  }

  answerRejected = (e, name) => {
    if (e !== 'SATISFIED') {
      if (!this.state.returnedAnswersArray.includes(name)) {
        this.setState({returnedAnswersArray: [...this.state.returnedAnswersArray, name]});
      }
    } else if (this.state.returnedAnswersArray.includes(name)) {
      this.setState({returnedAnswersArray: this.arrayRemove(this.state.returnedAnswersArray, name)});
    }
  }
  arrayRemove = (arr, value) => {
    return arr.filter((ele) => {
      return ele !== value;
    });
  }

  sortData = (data) => data.sort((a, b) => {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  });

  render() {
    const {
      initialValues, title, submitting, pristine, mode, formLoading, handleSubmit,
    } = this.props;
    const { returnedAnswersArray } = this.state;
    return (
      <FormWithLoading loading={formLoading} className="form form--horizontal px-2">
        <Card>
          <CardBody className="p-0 p-md-2">
            <h5>{title}</h5>
            {initialValues && initialValues.subStandardReviewDtoList && this.sortData(initialValues.subStandardReviewDtoList).map((item) => (
              <div style={{ borderBottom: '1px solid grey' }}>
                <div className="form__form-group-label mt-4">
                  <b> Standart: {item.title}</b>
                  <br />
                  <i> Açıklama: {item.description}</i>
                  <br />
                </div>
                { item.evalDtoList.map((evalItem, index) => (
                  <AnswerAcceptRejectRadioSection
                    label={evalItem.itemText}
                    // description={evalItem.description}
                    option1="Karşılanıyor"
                    value1="SATISFIED"
                    option2="Karşılanmıyor"
                    value2="NON_SATISFIED"
                    option3="Kısmen Karşılanıyor"
                    value3="REVISION"
                    disabledOption1={mode === 'view'}
                    disabledOption2={mode === 'view'}
                    disabledOption3={mode === 'view'}
                    index={index + 1}
                    standardItemId={evalItem.standardItemDto.id}
                    name={'standardId=' + evalItem.standardItemDto.id} // id ile değişecek
                    onChange={this.answerRejected}
                    returnedAnswer={evalItem.evalText && evalItem.evalText}
                    defaultChecked={evalItem.status === 'SATISFIED'}
                    defaultChecked2={evalItem.status === 'NON_SATISFIED'}
                    defaultChecked3={evalItem.status === 'REVISION'}
                    showReturnMessageInput={mode !== 'view' && returnedAnswersArray.includes('standardId=' + evalItem.standardItemDto.id)}
                  />
                ))}
              </div>
            ))}
            { mode !== 'view' &&
            <ButtonToolbar style={{ justifyContent: 'space-evenly', marginTop: '20px' }}>
              <>
                <Row className="w-100 mt-2">
                  <Col xs={12} className="mb-2 text-center">
                    <Button style={{ width: '180px' }} type="button" onClick={handleSubmit} disabled={pristine || submitting} color="success" size="sm">Kaydet</Button>
                  </Col>
                </Row>
              </>
            </ButtonToolbar>}

          </CardBody>
        </Card>
      </FormWithLoading>
    );
  }
}

const mapDispatchToProps = () => {
  return {
  };
};
const mapStateToProps = () => ({
});
AnswerVisitForm = reduxForm({
  form: 'Answer_Visit_Form', // a unique identifier for this form
  enableReinitialize: true,
  // onSubmitFail: (errors) => scrollToFirstError(errors),
})(AnswerVisitForm);
export default connect(mapStateToProps, mapDispatchToProps)(AnswerVisitForm);
