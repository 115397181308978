/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/static-property-placement */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
/* eslint-disable object-shorthand */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable arrow-parens */
/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import {
  Button, ButtonGroup, ButtonToolbar, Collapse,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import TopbarMenuLink from './TopbarMenuLink';
import { getUserName } from '../../App/helper/userProfile';
import { logout, refreshSession } from '../../../redux/actions/authAction';
import en from '../../../shared/img/en.png';
import tr from '../../../shared/img/tr.png';
import { userRolesControl } from '../../App/helper/auth-user-info';

class TopbarProfile extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      collapse: false,
      selectedLang: 'TR',
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  toggleLanguage = () => {
    this.setState(prevState => ({ collapseLanguage: !prevState.collapseLanguage }));
  };

  handleChange = (lang) => {
    this.setState({ selectedLang: lang });
  };

  render() {
    const { collapse, selectedLang } = this.state;
    return (
      <div className="h-100 d-flex">
        {/* <ButtonToolbar className="ms-auto" style={{ height: 'fit-content', alignSelf: 'center' }}>
          <ButtonGroup>
            <Button color="" size="sm" onClick={() => this.handleChange('TR')} className={selectedLang === 'TR' ? 'btn-custom-secondary' : ''}>
              TR
            </Button>
            <Button color="" size="sm" onClick={() => this.handleChange('EN')} className={selectedLang === 'EN' ? 'btn-custom-secondary' : ''}>
              EN
            </Button>
          </ButtonGroup>
        </ButtonToolbar> */}

        <button type="button" className="topbar__avatar ms-auto me-2" onClick={this.toggle}>
          {/* <img className="topbar__avatar-img" src={image} alt="avatar" /> */}
          <p className="topbar__avatar-name">
            <i className="lnr lnr-cog NavLinkIconResponsive" style={{ marginRight: '5px', marginLeft: '5px' }} />
            <span className="d-none d-md-block">
              {userRolesControl('ADMIN') ? 'ADMIN' : getUserName()}
            </span>
          </p>
        </button>
        {collapse && <button type="button" className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap" style={{ right: '50px' }}>
          <div className="topbar__menu">
            {userRolesControl('HOSPITAL') && (
            <>
              <TopbarMenuLink className="topbar__link-sign-out" title="Yetkili Bilgileri" icon="fa fa-id-card" path="/executive" onClickLink={() => { }} onClickBool />
              <div className="topbar__menu-divider" />
            </>
            )}
            <TopbarMenuLink className="topbar__link-sign-out" title="Şifre İşlemleri" icon="fas fa-lock" path="/change_password" onClickLink={() => { }} onClickBool />
            <div className="topbar__menu-divider" />
            <TopbarMenuLink className="topbar__link-sign-out" title="Sistemden Çık" icon="fas fa-power-off" path="/" onClickBool onClickLink={() => { logout(); }} />
          </div>
        </Collapse>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    refreshToken() {
      refreshSession(dispatch);
    },
  };
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)((TopbarProfile));
