/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */

/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable arrow-parens */
/* eslint-disable max-len */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-const */
/* eslint-disable object-curly-newline */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import HomePage from '../../HomePage/index';
import Login from '../../Login';
import { hasUserDashboardAccess, userAccessModuleControl, userRolesControl } from '../helper/auth-user-info';

function containsAny(source, target) {
  let result = source.filter(function (item) { return target.indexOf(item) > -1; });
  return (result.length > 0);
}

export const PrivateRoute = (props) => {
  const { component: Component, roles, accessModules, path, extraProps, ...rest } = props;
  return (
    <Route path={path} {...rest} render={props => {
      const currentUser = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : localStorage.getItem('userInfo');
      if (props && props.location && props.location.pathname && props.location.pathname.split('/')[1] === 'login') {
        return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
      }
      if (!currentUser) {
        return <Redirect to={{ pathname: '/welcome', state: { from: props.location } }} />;
      }
      if (roles && !containsAny(roles, currentUser.roles)) {
        return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
      }
      // if (path === '/dashboard' && !hasUserDashboardAccess()) {
      //   return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
      // }
      if (extraProps) {
        props = { ...props, ...extraProps };
      }
      return <Component {...props} />;
    }} />
  );
};
