/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable no-unused-vars */
/* eslint-disable react/static-property-placement */
/* eslint-disable react/sort-comp */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
/* eslint-disable arrow-parens */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-template */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable vars-on-top */
/* eslint-disable  no-var */
/* eslint-disable  no-plusplus */
/* eslint-disable  quotes */
/* eslint-disable  operator-linebreak */
/* eslint-disable  react/jsx-wrap-multilines */
/* eslint-disable  react/self-closing-comp */
/* eslint-disable  object-shorthand */
/* eslint-disable  object-curly-newline */
/* eslint-disable  func-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-multi-comp */
/* eslint-disable  react/no-did-update-set-state */
/* eslint-disable  no-lonely-if */
/* eslint-disable  space-in-parens */
/* eslint-disable  jsx-a11y/alt-text */
/* eslint-disable  no-nested-ternary */
/* eslint-disable  no-unneeded-ternary */
/* eslint-disable  implicit-arrow-linebreak */
/* eslint-disable  implicit-arrow-linebreak */
/* eslint-disable  no-sequences */

import React, { forwardRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import MaterialTable, { MTableFilterRow } from 'material-table';
// import moment from 'moment';
import TablePagination from '@material-ui/core/TablePagination';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { ButtonToolbar, Button, ButtonGroup } from 'reactstrap';
import RenderStatusChip from '../../../containers/App/helper/RenderStatusChip';

class Table extends PureComponent {
  static propTypes = {
    data: PropTypes.any,
    columns: PropTypes.array,
    isPaging: PropTypes.bool,
    newButtonBool: PropTypes.bool,
    refreshButtonBool: PropTypes.bool,
    filterButtonBool: PropTypes.bool,
    customButtonBool: PropTypes.bool,
  };

  static defaultProps = {
    data: [],
    columns: [],
    isPaging: true,
    newButtonBool: true,
    refreshButtonBool: false,
    filterButtonBool: false,
    customButtonBool: false,

  };

  constructor(props) {
    super(props);
    this.state = {
      // languages: i18next.languages[0] === 'en',
      selectedRow: null,
    };
    this.tableRef = React.createRef();
    this.theme = createMuiTheme({
      palette: {
        secondary: {
          main: '#70bbfd',
        },
      },
      typography: {
        fontFamily: 'Source Sans Pro, sans-serif',
        fontSize: 14,
        color: '#4D4F5C !important',
      },
      overrides: {
        MuiTableHead: {
          root: {
          },
        },
        MuiTableCell: {
          head: {
            fontSize: '14px',
            backgroundColor: '#00ffff17 !important',
            fontWeight: 600,
            padding: '5px 4px 5px 4px',
            color: '#4D4F5C !important',
          },
          root: {
            padding: '2px 4px 4px 4px',
            borderBottom: '1px solid #fff',
            color: '#4D4F5C !important',
            minHeight: '40px',
            height: '40px',
            verticalAlign: 'middle',
          },
          footer: {
            minHeight: 'auto !important',
            height: 'auto !important',
          },
        },
        MuiTableRow: {
          root: {
            fontSize: '13px',
            '&:nth-child(odd)': {
              backgroundColor: '#5f746440',
            },
            '&:nth-child(even)': {
              backgroundColor: '#F0F0F7',
            },
          },
          footer: {
            backgroundColor: '#fff !important',
          },
        },
      },
    });
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    const oldProps = this.props;
    const { isPaging } = this.props;
    if (isPaging && (oldProps.data !== newProps.data) && newProps.data.length !== 0) {
      this.tableRef.current && this.tableRef.current.onQueryChange({ page: newProps.data.page, pageSize: newProps.data.pageSize });
    }
  }

  onChangePage = (page) => {
    const { changePage } = this.props;
    changePage(page);
  }

  onChangeRowsPerPage = (itemsPerPagina) => {
    const { changeRowsPerPage } = this.props;
    changeRowsPerPage(itemsPerPagina);
  }

  byString = (o, s) => {
    if (!s) {
      return;
    }
    s = s.replace(/\[(\w+)\]/g, '.$1');
    s = s.replace(/^\./, '');
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
      var x = a[i];
      if (o && x in o) {
        o = o[x];
      } else {
        return;
      }
    }
    return o;
  };

  getFieldValue = (rowData, columnDef, lookup = true) => {
    let value = (typeof rowData[columnDef.field] !== 'undefined' ? rowData[columnDef.field] : this.byString(rowData, columnDef.field));
    if (columnDef.lookup && lookup) {
      value = columnDef.lookup[value];
    }
    return value;
  }

  // getTimeDiff = (strTime) => {
  //   return moment(strTime, 'DD/MM/YYYY HH:mm:ss').toDate().setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0);
  // }

  clickRouter(url, _id, _data) {
    if (_id !== undefined) {
      this.props.history.push({ pathname: url + _id, data: _data });
    } else {
      this.props.history.push(url);
    }
  }

  getLayeredObjectValue = (object, target) => {
    const split = target.split('.');
    let returnVal = object;
    const isId = split[split.length - 1] === 'id';
    for (let i = 0, len = isId ? split.length - 1 : split.length; i < len; i += 1) {
      returnVal = returnVal[split[i]] || false;
    }
    return isId ? (returnVal.name || returnVal.label || returnVal.title || '-') : returnVal || '-';
  }

  render() {
    const {
      data, columns, style, setSelectedRow, selectedRowId, isPaging, actions, newButtonBool, refreshButtonBool, actionsTitle, newButtonOnClick, prevSelectedRowId,
      customButtonBool, customButtonOnClick, customButtonColor, customButtonText,
      hasTabs, tabButtons, changeTab, currentTab, tableTitle, newButtonText, resetCitizenById, buttonToolbarBool, buttonToolbarButtons, // tab props
    } = this.props;
    const { selectedRow } = this.state;
    // let columnss = [];
    const options = {
      filtering: true,
      toolbar: true,
      showTitle: false,
      paging: false,
      rowStyle: (rowData, index) => ({
        backgroundColor: (prevSelectedRowId === rowData.id) ? '#c4e38e9e' : (index % 2) ? '#f1f6fa' : '#fefefe',
      }),
      filterCellStyle: { backgroundColor: '#F1F6FA' },
    };
    const tableColumns = columns.map((column, index) => {
      switch (column.field) {
        case 'locked':
          return {
            title: column.title,
            field: 'locked',
            render: rowData => {
              if (rowData.locked) {
                return <i style={{ color: '#d05a5a' }} className="fas fa-lock" />;
              }
              return <i style={{ color: 'green' }} className="fas fa-lock-open" />;
            },
          };
        case 'get_fullName_with_isPresident':
          return {
            title: column.title,
            field: 'name',
            render: rowData => {
              if (rowData.president) {
                return rowData.name + ' ' + rowData.surname + ' (Kurul Başkanı)';
              }
              return rowData.name + ' ' + rowData.surname;
            },
          };

        case 'title':
          return {
            title: column.title,
            field: 'title',
            cellStyle: {
              width: 300,
              maxWidth: 300,
            },
            headerStyle: {
              width: 300,
              maxWidth: 300,
            },
            render: rowData => {
              if (rowData.title) {
                return rowData.title;
              }
            },
          };
        default:
          return column;
      }
    });

    // const tableColumnsWithStatus = tableColumns && tableColumns.map(c => {
    //   if (c.lookup) {
    //     return {
    //       render: rowData => {
    //         return c.lookup[this.getLayeredObjectValue(rowData, c.field)];
    //       },
    //     };
    //   } else {
    //     return c;
    //   }
    // });
    return (
      <div className="material-table__wrap" style={style}>
        <MuiThemeProvider theme={this.theme}>
          <MaterialTable
            title="Styling with MuiThemeProvider Preview"
            columns={tableColumns}
            isLoading={data.loading}
            localization={{
              header: {
                actions: actionsTitle || 'İşlemler',
              },
              body: {
                emptyDataSourceMessage: (
                  <div style={{ height: "60vh", display: 'flex', alignContent: 'space-around', flexWrap: 'wrap', justifyContent: 'center' }}>
                      <div className="">Bilgi Yok</div>
                  </div>
                ),
              },
            }}
            // tableRef={this.tableRef}
            icons={{
              SortArrow: forwardRef((props, ref) => (<span {...props}><i className="fas fa-arrow-up" ref={ref} /></span>)),
              Filter: () => <i className="lnr lnr-funnel" />,
            }}
            data={(data && data.tableData && data.tableData.length) ? data.tableData : data.data ? data.data : undefined}
            actions={(actions) && actions}
            options={options}
            onRowClick={(event, rowData) => {
              if (selectedRowId === rowData.id) {
                setSelectedRow(undefined);
              } else {
                setSelectedRow(rowData);
              }
            }}
            components={{
              FilterRow: props => <MTableFilterRow {...props} />,
              Toolbar: props => (
                <div style={{ padding: '10px 0', display: 'flow-root' }}>
                  {/* <MTableToolbar {...props} /> */}
                  {tableTitle && <div style={{ display: 'contents', fontSize: '18px' }}> <b className="ps-2">{tableTitle}</b> </div>}
                  {(newButtonBool || refreshButtonBool || customButtonBool) &&
                  <ButtonToolbar style={{ padding: '0px 10px', float: 'right' }}>
                  {customButtonBool &&
                  <><Button
                    onClick={customButtonOnClick}
                    style={{ margin: '0.25rem' }}
                    size="sm"
                    color={customButtonColor || "custom-primary"}
                  >{customButtonText || "Yeni ekle"}
                    </Button>
                  </>}
                  {newButtonBool &&
                  <><Button
                    onClick={newButtonOnClick}
                    style={{ margin: '0.25rem' }}
                    size="sm"
                    color={"custom-success"}
                  >{newButtonText || "Yeni ekle"}
                    </Button>
                  </>}
                  </ButtonToolbar>}
                  {hasTabs &&
                    <ButtonGroup style={{ marginLeft: '0.5rem' }}>
                      {tabButtons && tabButtons.map(button => {
                        return (
                          <Button outline={button.index !== currentTab} size="sm" color={button.index === currentTab ? 'custom-primary' : 'custom-primary'} active={button.index === currentTab} onClick={() => changeTab(button.index)}>{button.text}</Button>
                        );
                      })}
                    </ButtonGroup>}
                </div>
              ),
            }}
          />
        </MuiThemeProvider>
        {isPaging && data && data.totalCount > 0 &&
          <div style={{ }}>
            <TablePagination
              style={{ padding: '10px', display: 'block', width: '100%', height: 'fit-content' }}
              emptyRowsWhenPaging={false}
              rowsPerPageOptions={[5, 10, 20, 50]}
              rowsPerPage={data.pageSize}
              count={data.totalRowCount || data.totalCount}
              page={data.pageIndex || data.page}
              labelRowsPerPage="Satır"
              onChangePage={(e, page) => {
                this.props.changePage(parseInt(page, 10));
              }}
              onChangeRowsPerPage={event => {
                this.props.changeRowsPerPage(parseInt(event.target.value, 10));
              }}
              labelDisplayedRows={({ from, to, count }) => `${count} sonuçtan ${from}-${to} arası`}
              onSelectionChange={(rows) => { this.changeSelection(rows); }}
              ActionsComponent={(props) => {
                return (
                  <ButtonGroup style={{ paddingLeft: '0.5rem' }}>
                    <Button style={{ padding: '5px 15px' }} outline size="sm" disabled={props.page === 0} onClick={() => props.onChangePage('', 0)}><i className="fas fa-angle-double-left" /></Button>
                    <Button style={{ padding: '5px 15px' }} outline size="sm" disabled={props.page === 0} onClick={() => props.onChangePage('', props.page - 1)}><i className="fas fa-angle-left" /></Button>
                    <Button style={{ padding: '5px 15px' }} outline size="sm" disabled={props.page === Math.floor(props.count / props.rowsPerPage)} onClick={() => props.onChangePage('', props.page + 1)}><i className="fas fa-angle-right" /></Button>
                    <Button style={{ padding: '5px 15px' }} outline size="sm" disabled={props.page === Math.floor(props.count / props.rowsPerPage)} onClick={() => props.onChangePage('', Math.floor(props.count / props.rowsPerPage))}><i className="fas fa-angle-double-right" /></Button>
                  </ButtonGroup>
                );
              }}
            />
          </div>}
      </div>
    );
  }
}

export default withRouter(Table);
