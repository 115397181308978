/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import { Card, CardBody } from 'reactstrap';
import AddApplicationForm from '../Applications/compontents/AddApplicationForm';

class NewApplication extends PureComponent {
  render() {
    const { history } = this.props;
    return (
      <Card>
        <CardBody className="p-0 p-md-2">
          <AddApplicationForm history={history} />

        </CardBody>
      </Card>
    );
  }
}

export default NewApplication;
