/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';
import { Alert, AlertTitle } from '@mui/material';

const RequiredConditionsForNST = (props) => (
  <div>
    <Alert className="mb-3" severity={props?.initialValues?.criteriaList?.filter((el) => el.provided === false).length === 0 ? 'success' : 'error'} style={{ color: props?.initialValues?.criteriaList?.filter((el) => el.provided === false).length === 0 ? 'rgb(32 95 61)' : 'rgb(95, 33, 32)', backgroundColor: props?.initialValues?.criteriaList?.filter((el) => el.provided === false).length === 0 ? 'rgb(237 253 237)' : 'rgb(253, 237, 237)' }}>
      {props?.initialValues?.criteriaList?.filter((el) => el.provided === false).length === 0
        ? <AlertTitle>Başvuru oluşturabilmek için gerekli şartlar sağlandı.</AlertTitle>
        : (
          <>
            <AlertTitle>Başvuru oluşturabilmek için gerekli şartlar sağlanmadı.</AlertTitle>
            <AlertTitle>&apos;Nütrisyon Ekibini Düzenle&apos; butonuna tıklayarak nütrisyon destek ekibinize gerekli üyeleri eklemeniz gerekmektedir.</AlertTitle>
          </>
        )}
      <ul className="mb-0" style={{ listStyle: 'none' }}>
        {props?.initialValues?.criteriaList?.map((item) => (
          <li className={item.provided ? 'text-success' : 'text-danger'}>{item.provided ? <i className="lnr lnr-checkmark-circle text-success" /> : <i className="lnr lnr-cross-circle text-danger" />} {item.trDescription}</li>
        ))}
      </ul>
    </Alert>
  </div>
);

export default RequiredConditionsForNST;
