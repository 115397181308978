/* eslint-disable react/static-property-placement */
/* eslint-disable react/require-default-props */
/* eslint-disable object-curly-newline */
/* eslint-disable class-methods-use-this */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable class-methods-use-this */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default class TopbarMenuLinks extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    className: PropTypes.string,
    onClickBool: PropTypes.bool,
    onClickLink: PropTypes.func,
  };

  static defaultProps = {
    onClickBool: false,
    className: '',
  };

  handleClick() {
    const { onClickLink } = this.props;
    onClickLink();
  }

  render() {
    const { title, icon, path, onClickBool, className } = this.props;

    return (
      <>
        {
          onClickBool ?
            <Link className={`topbar__link ${className}`} to={path} onClick={(e) => this.handleClick(e)}>
              <i className={`${icon}`} />
              <p className="topbar__link-title" style={{ marginLeft: '5px' }}>{title}</p>
            </Link>
            :
            <Link className={`topbar__link ${className}`} to={path}>
              <i className={`${icon}`} />
              <p className="topbar__link-title" style={{ marginLeft: '5px' }}>{title}</p>
            </Link>
        }
      </>
    );
  }
}
