/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/static-property-placement */
/* eslint-disable arrow-parens */
/* eslint-disable no-shadow */
/* eslint-disable arrow-body-style */
/* eslint-disable object-shorthand */
/* eslint-disable react/prop-types */
/* eslint max-len: ["error", { "code": 400 }] */
/* eslint-disable no-unused-vars */
/* eslint-disable block-spacing */
/* eslint-disable no-lone-blocks */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable object-curly-newline */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
/* eslint-disable no-mixed-operators */
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { initialize, startSubmit } from 'redux-form';
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { Chip } from '@material-ui/core';
import { getById } from '../../../redux/actions/byIdAction';
import RegisterRevisionRejectForm from './RegisterRevisionRejectForm';
import { download } from '../../../redux/actions/downloadAction';
import { hospitalRegisterStatus } from '../../App/helper/statusTableLookupValues';

class HospitalRegisterDetail extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    loginType: PropTypes.string.isRequired,
    setLoginActiveTab: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      showRevisionMessage: false,
      showRejectMessage: false,
    };
  }

  componentDidMount() {
    const { id, fetchHospitalRegister } = this.props;
    if (id) {
      fetchHospitalRegister(id);
    }
  }

  componentDidUpdate(prevProps) {
    const { id, fetchHospital } = this.props;
    // if (prevProps.id !== id && id) {
    //   fetchHospital(id);
    // }
  }

  showTextArea = (type) => {
    if (type === 'revision') {
      this.setState({ showRevisionMessage: true, showRejectMessage: false });
    } else if (type === 'reject') {
      this.setState({ showRejectMessage: true, showRevisionMessage: false });
    } else {
      this.setState({ showRejectMessage: false, showRevisionMessage: false });
    }
  }

  onSubmit = (values) => {
    const { revisionRegister, rejectRegister, hospitalRegisterData } = this.props;
    const { showRevisionMessage, showRejectMessage } = this.state;
    if (showRevisionMessage) {
      revisionRegister(hospitalRegisterData.id, values);
    }
    if (showRejectMessage) {
      rejectRegister(hospitalRegisterData.id, values);
    }
  }

  getRegisterStatusChip = (status) => {
    if (status === 'CONFIRMED') {
      return <Chip label="Onaylandı" className="bg-custom-success" />;
    }
    if (status === 'REJECTED') {
      return <Chip label="Reddedildi" className="bg-custom-danger" />;
    }
    if (status === 'REVISION') {
      return <Chip label="Revizyon" className="bg-custom-warning" />;
    }
    return <Chip label={status} className="bg-info" />;
  }

  getDocument = (token) => {
    const { downloadDocument } = this.props;
    downloadDocument(token);
  }

  render() {
    const { hospitalRegisterData, confirmRegister, revisionRegister, rejectRegister } = this.props;
    const { showRevisionMessage, showRejectMessage } = this.state;
    return (
      <Card>
        {hospitalRegisterData &&
        <CardHeader>
          <div className="d-flex" style={{ justifyContent: 'space-between' }}>
            <h4>Hastane Kayıt Bilgileri</h4>
            {hospitalRegisterStatus[hospitalRegisterData.status]}
          </div>
          {hospitalRegisterData.status === 'REJECTED' &&
          <div className="text-danger"><b>Reddetme Mesajı: </b>
            {hospitalRegisterData.rejectMessage}
          </div>}
          {hospitalRegisterData.status === 'REVISION' &&
          <div className="text-danger"><b>Revizyon Mesajı: </b>
            {hospitalRegisterData.revisionMessage}
          </div>}
        </CardHeader>}

        <CardBody className="p-0 p-md-2">
          {hospitalRegisterData &&
          <Row>
            <Col xs={12}>
              <h5>{hospitalRegisterData.title}</h5>
              <i>
                {`${hospitalRegisterData.countyDto.cityDto.name} ${hospitalRegisterData.countyDto.name}`}
              </i>
              <hr />
            </Col>
            <Col xs={12} className="mb-3">
              Yetkili Kişi: {`${hospitalRegisterData.authorityName} ${hospitalRegisterData.authoritySurname}`}
            </Col>
            <Col xs={12} className="mb-3">
              Yetkili Görevi: {`${hospitalRegisterData.authorityDuty}`}
            </Col>
            <Col xs={12} className="mb-3">
              Yetkili E-Posta: {`${hospitalRegisterData.authorityEmail}`}
            </Col>
            <Col xs={12} className="mb-3">
              Yetkili Yetki Belgesi: {hospitalRegisterData.hasDocument ?
                <Button className="ms-2" color="outline-primary" size="sm" onClick={() => this.getDocument(hospitalRegisterData.token)}> Belgeyi İndir <i className="lnr lnr-download" /> </Button>
              :
              'Yetki belgesi yüklenmemiş.' }
            </Col>
          </Row>}
          {hospitalRegisterData && (hospitalRegisterData.status === 'NEW' || hospitalRegisterData.status === 'REVISION') &&
            <Row className="mt-3">
              <Col>
                <Button className="btn-custom-danger" size="sm" onClick={() => this.showTextArea('reject')}>
                  Reddet
                </Button>
              </Col>
              <Col className="text-center">
                <Button className="btn-custom-warning" size="sm" onClick={() => this.showTextArea('revision')}>
                  Revizyon İste
                </Button>
              </Col>
              <Col className="text-end">
                <Button className="btn-custom-success" size="sm" onClick={() => confirmRegister(hospitalRegisterData.id)}>
                  Onayla
                </Button>
              </Col>
              { (showRevisionMessage || showRejectMessage) &&
              <div className="mt-3">
                <RegisterRevisionRejectForm onSubmit={this.onSubmit} revisionRegister={showRevisionMessage} rejectRegister={showRejectMessage} showTextArea={this.showTextArea} />
              </div>}

            </Row>}

        </CardBody>

      </Card>

    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchHospitalRegister(id) {
      dispatch(getById('hospital/register', id));
    },
    downloadDocument(token) {
      dispatch(download('hospital/register/public', token));
    },
  };
};

const mapStateToProps = (state) => ({
  hospitalRegisterData: state.getHospitalRegister.data,
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HospitalRegisterDetail));
