/* eslint-disable arrow-parens */
/* eslint-disable quotes */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable object-shorthand */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
/* eslint-disable no-lonely-if */
/* eslint-disable object-curly-newline */
/* eslint-disable no-prototype-builtins */

import axios from 'axios';
import { addNotification } from './notificationActions';
import { authHeader } from '../../containers/App/helper/auth-header';
// import { userRolesControl, getUserId } from '../../containers/App/helper/auth-user-info';

function request(namespace) {
  return {
    type: `${namespace}/LIST_REQUEST`,
  };
}

function success(namespace, data) {
  return {
    type: `${namespace}/LIST_SUCCESS`,
    data,
  };
}

function failure(namespace, error) {
  return {
    type: `${namespace}/LIST_FAILURE`,
    error,
  };
}


export function list(namespace, isPostMethod, id) {
  return dispatch => {
    dispatch(request(namespace));
    if (isPostMethod) {
      axios.post(`${process.env.REACT_APP_API_URL}/${namespace}/list${id ? `/${id}` : ''}`, {}, authHeader())
        .then(res => {
          if (res.data && res.data.errorCode) {
            dispatch(addNotification(res.data.errorCode, 'error'));
          } else {
            dispatch(success(namespace, res.data));
          }
        })
        .then(() => {
        })
        .catch((error) => {
          dispatch(failure(namespace, error));
          if (error.response && error.response.data !== undefined) {
            if (error.response.data.errorMessage) {
              dispatch(addNotification(error.response.data.errorMessage, 'error'));
            }
          } else {
            dispatch(addNotification('500', 'error'));
          }
        });
    } else {
      axios.get(`${process.env.REACT_APP_API_URL}/${namespace}/list${id ? `/${id}` : ''}`, authHeader())
        .then(res => {
          if (res.data.errorCode) {
            dispatch(addNotification(res.data.errorCode, 'error'));
          } else {
            dispatch(success(namespace, res.data));
          }
        })
        .then(() => {
        })
        .catch((error) => {
          dispatch(failure(namespace, error));
          if (error.response && error.response.data !== undefined) {
            if (error.response.data.errorMessage) {
              dispatch(addNotification(error.response.data.errorMessage, 'error'));
            }
          } else {
            dispatch(addNotification('500', 'error'));
          }
        });
    }
  };
}

