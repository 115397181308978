/* eslint-disable react/no-string-refs */
/* eslint-disable no-useless-constructor */
/* eslint-disable object-curly-newline */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NotificationSystem from 'react-notification-system';
import { addNotification } from '../../redux/actions/notificationActions';

class NotificationContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.notificationSystem = this.refs.notificationSystem;
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    const { message, level, position, autoDismiss } = newProps.notification;
    this.notificationSystem.addNotification({
      message,
      level,
      position,
      autoDismiss,
    });
  }

  render() {
    return (
      <NotificationSystem ref="notificationSystem" />
    );
  }
}

function mapStateToProps(state) {
  return {
    notification: state.notification,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      addNotification,
    }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationContainer);
