/* eslint-disable react/static-property-placement */
/* eslint-disable jsx-a11y/label-has-for,guard-for-in */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/sort-comp */
/* eslint max-len: ["error", { "code": 400 }] */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable arrow-parens */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable no-class-assign */
/* eslint-disable object-shorthand */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-confusing-arrow */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable prefer-template */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-else-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable  react/self-closing-comp */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable dot-notation */
/* eslint-disable lines-between-class-members */
/* eslint-disable object-curly-spacing */
/* eslint-disable max-len */

import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Row,
} from 'reactstrap';
import { Field, reduxForm, initialize, FormSection, FieldArray, reset, change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import Alert from '../../../../shared/components/Alert';
// import { info, viewInfo } from '../../../../redux/actions/form/infoAction';
import FormWithLoading from '../../../shared/components/form/FormWithLoading';
import TextAreaSection from '../../../shared/components/generic-form/FormSections/SingleSections/TextAreaSection';
import { scrollToFirstError } from '../../../shared/components/generic-form/submitFail';
import PdfUploadFormSection from '../../../shared/components/generic-form/FormSections/PdfUploadFormSection';
import InputSection from '../../../shared/components/generic-form/FormSections/SingleSections/InputSection';
// import { rules } from '../../../../shared/components/generic-form/validate';
// import PageTitleFilter from '../../../../shared/components/PageTitleFilter';
// import ReactTooltip from '../../../../../node_modules/react-tooltip';
const selector = formValueSelector('Upload_Pdf_With_Name_Modal_Form');

class UploadPdfWithNameForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    submitting: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const { tcNo, getByTc } = this.props;
  }

  componentDidUpdate(prevProps) {
    const { initialValues, initialize, tcNo, id, info, getByTc } = this.props;
  }

  formSubmit = (handleSubmit, togglePdfModal) => {
    handleSubmit();
    togglePdfModal();
  }

  render() {
    const {
      pristine, submitting, file, reset, handleSubmit, title, // redux-form props
      togglePdfModal, formLoading,
    } = this.props;
    return (
      <FormWithLoading
        loading={formLoading}
        className="form form--horizontal"
      >
        <Row>
          <Col>
            <InputSection
              label="Belge Adı"
              name="title"
              type="text"
              validate={['required']}
            />
          </Col>
          <Col>
            <FormSection name="">
              <PdfUploadFormSection
                name="file"
                label="Belge Yükle"
                validate={['required']}
              />
            </FormSection>
          </Col>
        </Row>
        <ButtonToolbar style={{ justifyContent: 'space-evenly', marginTop: '20px' }}>
          <Button onClick={reset} disabled={pristine || submitting} variant="contained">Sıfırla</Button>
          <Button type="button" onClick={() => this.formSubmit(handleSubmit, togglePdfModal)} disabled={((file === undefined) || (file.length === 0)) || (title === undefined)} color="success">Kaydet</Button>
        </ButtonToolbar>
      </FormWithLoading>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  };
};
const mapStateToProps = (state) => ({
  file: selector(state, 'file') !== undefined ? selector(state, 'file') : undefined,
  title: selector(state, 'title') !== undefined ? selector(state, 'title') : undefined,
});

UploadPdfWithNameForm = reduxForm({
  form: 'Upload_Pdf_With_Name_Modal_Form', // a unique identifier for this form
  enableReinitialize: true,
})(UploadPdfWithNameForm);

export default connect(mapStateToProps, mapDispatchToProps)(UploadPdfWithNameForm);
