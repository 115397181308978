/* eslint-disable arrow-parens */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/static-property-placement */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getAppeal } from '../../../redux/actions/appealAction';
import { fetchTableData } from '../../../redux/actions/listTableAction';
import Table from '../../../shared/components/generic-table/Table';
import GenericModal from '../../../shared/components/GenericModal';
import { appealStatus } from '../../App/helper/statusTableLookupValues';
import NutritionTeamMembersList from '../NutritionTeamMembersList';

class HospitalApplicationsTableContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowId: undefined,
      selectedRowData: undefined,
      prevSelectedRowId: undefined,
      isModalOpen: false,
    };
  }

  componentDidMount() {
    const { fetchTableData } = this.props;
    fetchTableData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { createAppealSuccess, createAppealData, history } = this.props;
    const { selectedRowId } = this.state;
    if (prevState.selectedRowId !== selectedRowId && selectedRowId) {
      this.toggle();
    }
    if (prevProps.createAppealSuccess !== createAppealSuccess && createAppealSuccess) {
      history.push('/new-application');
    }
  }

  toggle = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  }

  setSelectedRow = (rowData) => {
    const { history } = this.props;
    if (!rowData) {
      // undefined: form is closed, null: form is open and create mode, {data}: edit mode
      this.setState({
        selectedRowId: rowData === undefined ? undefined : null,
        selectedRowData: rowData === undefined ? undefined : null,
      });
    } else {
      this.setState({
        selectedRowId: rowData.id,
        selectedRowData: rowData,
        prevSelectedRowId: rowData.id,
      });
      history.push({
        pathname: '/new-application',
        state: { appealId: rowData.id },
      });
    }
  };

  createAppealOnClick=() => {
    const { createAppeal } = this.props;
    this.toggle();
  }

  confirm=() => {
    const { createAppeal } = this.props;
    createAppeal();
  }

  routeToNst=() => {
    const { history } = this.props;
    history.push('/NST');
  }

  render() {
    const {
      tableData, setSelectedRow, tabs, changeTab, currentTabIndex, fetchTableData, history, appealList,
    } = this.props;
    const {
      isModalOpen, selectedRowId, prevSelectedRowId, selectedRowData,
    } = this.state;

    return (
      <div>
        <Table
          data={appealList}
          newButtonOnClick={() => { this.createAppealOnClick(); }}
          newButtonText="Yeni Başvuru"
          refreshButtonBool
          refreshButtonAction={() => fetchTableData()}
          columns={[
            { title: 'Başvuru No', field: 'code' },
            { title: 'Başvuru Sahibi', field: 'hospitalDto.title' },
            { title: 'Başvuru tarihi', field: 'appealTime' },
            { title: 'Durum', field: 'status', lookup: appealStatus },
          ]}
          setSelectedRow={this.setSelectedRow}
          selectedRowId={selectedRowId}
          prevSelectedRowId={prevSelectedRowId}
          changeRowsPerPage={this.changeRowsPerPage}
          changePage={this.changePage}
          hasTabs
          tabButtons={tabs}
          changeTab={changeTab}
          currentTab={currentTabIndex}
          maxBodyHeight={false}
        />
        <GenericModal
          isModalOpen={isModalOpen}
          button={(
            <button type="button" className="d-none">gizli buton</button>
              )}
          toggleModal={this.toggle}
          content={
            <NutritionTeamMembersList confirmAction={this.confirm} toggle={this.toggle} routeToNst={this.routeToNst} />
            }
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchTableData() {
    dispatch(fetchTableData('appeal'));
  },
  createAppeal() {
    dispatch(getAppeal('create'));
  },
});
const mapStateToProps = (state) => ({
  appealList: state.appealList,
  createAppealSuccess: state.createAppeal.success,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HospitalApplicationsTableContainer));
