/* eslint-disable arrow-parens */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/static-property-placement */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import { getVisit } from '../../redux/actions/visitAction';
import Table from '../../shared/components/generic-table/Table';
import GenericModal from '../../shared/components/GenericModal';
import { appealStatus, visitStatus } from '../App/helper/statusTableLookupValues';
import CreateVisitReview from './CreateVisitReview';

class NewVisitsTableContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowId: undefined,
      prevSelectedRowId: undefined,
      isModalOpen: false,
    };
  }

  componentDidMount() {
    const { fetchTableData } = this.props;
    fetchTableData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { reviewPublishVisitSuccess, fetchTableData } = this.props;
    const { selectedRowId } = this.state;
    if (prevState.selectedRowId !== selectedRowId && selectedRowId) {
      this.toggle();
    }
    if (prevProps.reviewPublishVisitSuccess !== reviewPublishVisitSuccess && reviewPublishVisitSuccess) {
      this.toggle();
      fetchTableData();
    }
  }

  toggle = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  }

  setSelectedRow = (rowData) => {
    const { history } = this.props;
    if (!rowData) {
      this.setState({
        selectedRowId: rowData === undefined ? undefined : null,
      });
    } else {
      this.setState({
        selectedRowId: rowData.id,
        prevSelectedRowId: rowData.id,
      });
    }
  };

  render() {
    const {
      tabs, changeTab, currentTabIndex, visitList,
    } = this.props;
    const {
      selectedRowId, prevSelectedRowId, isModalOpen,
    } = this.state;
    return (
      <div>
        <Table
          data={visitList}
          newButtonBool={false}
          columns={[
            { title: 'Başvuru No', field: 'appealDto.code' },
            { title: 'Görüşme No', field: 'code' },
            { title: 'Başvuru tarihi', field: 'appealDto.appealTime' },
            { title: 'Hastane Adı', field: 'appealDto.hospitalDto.title' },
            { title: 'Hastane İli', field: 'appealDto.hospitalDto.countyDto.cityDto.name' },
            { title: 'Hastane İlçesi', field: 'appealDto.hospitalDto.countyDto.name' },
            { title: 'Yerinde Görüşme Tarihi', field: 'visitDate' },
            { title: 'Başvuru Durumu', field: 'appealDto.status', lookup: appealStatus },
            { title: 'Görüşme Durumu', field: 'status', lookup: visitStatus },
          ]}
          setSelectedRow={this.setSelectedRow}
          selectedRowId={selectedRowId}
          prevSelectedRowId={prevSelectedRowId}
          changeRowsPerPage={this.changeRowsPerPage}
          changePage={this.changePage}
          hasTabs
          tabButtons={tabs}
          changeTab={changeTab}
          currentTab={currentTabIndex}
          maxBodyHeight={false}
        />
        <GenericModal
          isModalOpen={isModalOpen}
          button={<Button className="d-none my-2" style={{ width: 0 }} />}
          toggleModal={this.toggle}
          onClosed={() => this.setSelectedRow(null)}
          content={(
            <div>
              <CreateVisitReview id={selectedRowId} />
            </div>
              )}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchTableData() {
    dispatch(getVisit('list'));
  },
});
const mapStateToProps = (state) => ({
  visitList: state.listVisit,
  createAppealSuccess: state.createAppeal.success,
  reviewPublishVisitSuccess: state.reviewPublishVisit.success,

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NewVisitsTableContainer));
