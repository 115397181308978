/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/static-property-placement */
/* eslint-disable no-trailing-spaces */

/* eslint max-len: ["error", { "code": 300 }] */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
/* eslint-disable arrow-parens */
/* eslint-disable no-shadow */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable  react/forbid-prop-types */
/* eslint-disable  react/no-did-update-set-state */
/* eslint-disable  react/jsx-boolean-value */
/* eslint-disable  no-param-reassign */
/* eslint-disable  vars-on-top */
/* eslint-disable  no-var */
/* eslint-disable  curly */
/* eslint-disable  nonblock-statement-body-position */

import React, { PureComponent, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import renderReactMultiSelectField from './MultiSelect';
import { rules } from '../../validate';

class MultiSelectSection extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    mode: PropTypes.oneOf(['create', 'edit', 'view']).isRequired,
    initialValues: PropTypes.any,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    submessage: PropTypes.string,
    character: PropTypes.string,
    isDisabled: PropTypes.bool,
    validate: PropTypes.array,
    max: PropTypes.number,
  };

  static defaultProps = {
    initialValues: undefined,
    submessage: undefined,
    isDisabled: false,
    validate: [],
    character: undefined,
    max: undefined,
  };

  getLayeredObjectValue = (object, target) => {
    const split = target.split('.');
    let returnValArray = object;
    for (let i = 0, len = split.length; i < len; i += 1) {
      returnValArray = returnValArray[split[i]] || false;
    }
    return returnValArray;
  }

  render() {
    const {
      t, mode, initialValues, max, label,
      placeholder, name, type, submessage,
      character, isDisabled, validate, style,
      items, loading, validateWithoutTouch,
    } = this.props;
    const capitalize = (value) => value && value.toLocaleUpperCase('tr-TR');
    return (
      <div className="form__form-group">
        <span className="form__form-group-label">{label}
        </span>
        <div className="form__form-group-field" style={{ fontSize: '12px' }}>
          {mode !== 'view' ?
            <Field
              name={name}
              component={renderReactMultiSelectField}
              showSelectedItems
              items={items}
              maxSelectedItems={max}
              loading={loading}
              validate={validate && validate.map(rule => rules[rule])}
              validateWithoutTouch={validateWithoutTouch}
            />
            :
            initialValues !== undefined && this.getLayeredObjectValue(initialValues, name)
              ? this.getLayeredObjectValue(initialValues, name).map(obj => {
                return <li style={{ fontStyle: 'italic' }}>{obj.name || obj.label || obj.title || ''}</li>;
              })
              : <p>-</p>}
        </div>
        {mode !== 'view' && character &&
          <span className="form__form-group-character" style={{ fontSize: '11px' }}>
            {character}
          </span>}
      </div>
    );
  }
}

export default MultiSelectSection;
