/* eslint-disable no-plusplus */
/* eslint-disable max-len */

import { removeToken } from './auth-token';

function getUserJSON() {
  const user = localStorage.getItem('userInfo');
  return user && JSON.parse(user);
}

export function getUserRoles() {
  return getUserJSON() && getUserJSON().roles;
}

export function userRolesControl(value) {
  const userRoles = getUserRoles();
  if (!userRoles) {
    removeToken();
    localStorage.removeItem('userInfo');
    window.location.href = '/login';
    return false;
  }
  return userRoles.includes(value);
}

export function getUserAccessModules() {
  return getUserJSON() && getUserJSON().accessModules && getUserJSON().accessModules;
}

export function userAccessModuleControl(value) {
  return getUserAccessModules() && getUserAccessModules().includes(value);
}

export function getPersonnelId() {
  return getUserJSON().personnel && getUserJSON().personnel.id;
}

export function getUserName() {
  return getUserJSON().personnel && getUserJSON().personnel.name;
}

export function getUserPrivilages() {
  return getUserJSON() && getUserJSON().roles;
}

export function getUserModules() {
  let privileges = getUserPrivilages();
  privileges = privileges && privileges.filter((prv) => prv.includes('SCREEN')).map((e) => e.replace('_SCREEN', '')).sort();
  return privileges;
}

export function getUserDepartments() {
  const userJSON = getUserJSON();
  return userJSON && userJSON.departments;
}

export function userDepartmentControl(departmentId) {
  const userDepartments = getUserDepartments();
  return departmentId && userDepartments && userDepartments.includes(+departmentId);
}

export function getUserId() {
  const userJSON = getUserJSON();
  return userJSON && userJSON.userId;
}
export function getMemberId() {
  const userJSON = getUserJSON();
  return userJSON && userJSON.memberId;
}

export function nullCheck(path) {
  const splittedPath = path.split('.');
  let value = '';
  for (let i = 1; i < splittedPath.length + 1; i++) {
    for (let j = 0; j < i; j++) {
      if (j + 1 === i) {
        value += `${splittedPath[j]}`;
      } else {
        value += `${splittedPath[j]}.`;
      }
      if (j + 1 !== i) {
        value += ' && ';
      }
    }
  }
  return value;
}

export function hasUserDashboardAccess() {
  const userJSON = getUserJSON();
  return userJSON && userJSON.hasDashboardAccess;
}
