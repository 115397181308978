/* eslint-disable max-len */
/* eslint-disable  no-unused-vars */
import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { reducer as reduxFormReducer } from 'redux-form';
import { jwtRefreshMiddleware } from './middleware/jwtRefreshMiddleware';
import {
  sidebarReducer,
  notification,
  tabsReducer,
} from '../../redux/reducers/index';
import { listTableReducer } from '../../redux/reducers/listTableReducer';
import { saveReducer } from '../../redux/reducers/saveReducer';
import { listReducer } from '../../redux/reducers/listReducer';
import { infoReducer } from '../../redux/reducers/infoReducer';
import authReducer from '../../redux/reducers/authReducer';
import { resetPasswordReducer } from '../../redux/reducers/passwordReducer';
import { byIdReducer } from '../../redux/reducers/byIdReducer';
import { filterValue } from '../../redux/reducers/filterValueReducer';
import { getReducer } from '../../redux/reducers/getReducer';
import { uploadReducer } from '../../redux/reducers/uploadReducer';
import { addressReducer } from '../../redux/reducers/addressReducer';
import { imageDownloadReducer } from '../../redux/reducers/imageDownloadReducer';
import { getCitiesReducer } from '../../redux/reducers/getCitiesReducer';
import { register } from '../../redux/reducers/registerReducer';
import { confirm } from '../../redux/reducers/confirmReducer';
import { reject } from '../../redux/reducers/rejectReducer';
import { revision } from '../../redux/reducers/revisionReducer';
import { appealReducer } from '../../redux/reducers/appealReducer';
import { visitReducer } from '../../redux/reducers/visitReducer';
import { putReducer } from '../../redux/reducers/putReducer';
import { postReducer } from '../../redux/reducers/postReducer';

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  sidebar: sidebarReducer,
  tabs: tabsReducer,
  notification,
  auth: authReducer,

  // table datas
  testListTable: listTableReducer('test'),
  confirmedHospitals: listTableReducer('hospital'),
  newHospitals: listTableReducer('hospital/register'),
  appealList: listTableReducer('appeal'),

  // confirm
  hospitalRegisterConfirm: confirm('hospital/register'),

  // reject
  hospitalRegisterReject: reject('hospital/register'),

  // reject
  hospitalRegisterRevision: revision('hospital/register'),

  // upload
  testUpload: uploadReducer('test'),
  hospitalRegisterPublicUpload: uploadReducer('hospital/register/public'),
  uploadDocumentAppeal: uploadReducer('appeal/document/appointment'),
  uploadvisitReview: uploadReducer('visit/review'),
  uploadAppealAttachmentDocument: uploadReducer('appeal/document/attachment'),

  // register
  hospitalRegister: register('hospital'),

  // address
  getCities: getCitiesReducer(),
  countyList: addressReducer('county/bycity'),
  districtList: addressReducer('district/bycounty'),
  streetList: addressReducer('street/bydistrict'),

  // byId
  getHospital: byIdReducer('hospital'),
  getHospitalRegister: byIdReducer('hospital/register'),
  councilMemberById: byIdReducer('council/member'),
  externalVisitorById: byIdReducer('external/visitor'),
  hospitalNutritionById: byIdReducer('hospital/nutrition/member'),
  appealDocumentAttachmentDeleteById: byIdReducer('appeal/document/attachment/delete'),

  // appeal
  listAppeal: appealReducer('list'),
  createAppeal: appealReducer('create'),
  saveAppeal: appealReducer('save'),
  rejectAppeal: appealReducer('reject'),
  cancelAppeal: appealReducer('cancel'),
  deleteAppeal: appealReducer('delete'),
  idAppeal: appealReducer('id'),
  updateAppeal: appealReducer('update'),
  reviewAddAppeal: appealReducer('review/add'),
  reviewPublishAppeal: appealReducer('review/publish'),
  sendReviewAppeal: appealReducer('send/review'),
  setDatesAppeal: appealReducer('set/dates'),

  confirmVisitAppeal: appealReducer('confirm/visit'),
  accConfirmAppeal: appealReducer('confirm/acc'),
  nutritionReviewAddAppeal: appealReducer('nutrition/review/add'),
  extendAppeal: appealReducer('extend'),

  // visit
  listVisit: visitReducer('list'),
  listReviewVisit: visitReducer('review/list'),
  createVisit: visitReducer('create'),
  idVisit: visitReducer('id'),
  reviewCreateVisit: visitReducer('review/create'),
  reviewSaveVisit: visitReducer('review/save'),
  reviewByVisit: visitReducer('review/byvisit'),
  reviewIdVisit: visitReducer('review/id'),
  reviewUpdateVisit: visitReducer('review/update'),
  reviewPublishVisit: visitReducer('review/publish'),
  reviewCalculateVisit: visitReducer('review/calculate'),
  visitReviewSetRevisit: visitReducer('review/set/revisit'),
  visitSetDate: visitReducer('set/date'),

  // save
  councilMemberSave: saveReducer('council/member'),
  externalVisitorSave: saveReducer('external/visitor'),
  hospitalNutritionSave: saveReducer('hospital/nutrition/member'),

  // list
  councilMemberList: listReducer('council/member'),
  externalVisitorList: listReducer('external/visitor'),
  hospitalNutritionList: listReducer('hospital/nutrition/member'),
  appealDocumentAttachment: listReducer('appeal/document/attachment'),

  // PUT
  councilMemberUpdate: putReducer('council/member/update'),
  externalVisitorUpdate: putReducer('external/visitor/update'),
  userPasswordChange: putReducer('user/pass/change'),
  hospitalNutritionUpdate: putReducer('hospital/nutrition/member/update'),
  nutritionMemberDelete: putReducer('hospital/nutrition/member/delete'),
  userPassChange: putReducer('user/pass/change'),
  hospitalAuthorityChange: putReducer('hospital/authority/change'),

  // info
  testInfo: infoReducer('test'),

  // password
  resetPassword: resetPasswordReducer(),

  // get
  nutritionMemberValidate: getReducer('hospital/nutrition/member/check'),
  getHospitalRegisterWithToken: getReducer('hospital/register/public/bytoken'),
  getHospitalAuthorityCurrent: getReducer('hospital/authority/current'),

  // download
  testImageDownload: imageDownloadReducer('test'),

});

const store = createStore(reducer, applyMiddleware(jwtRefreshMiddleware, thunk));
// const store = createStore(reducer, applyMiddleware(thunk));
export default store;
