/* eslint-disable no-unused-vars */
/* eslint-disable arrow-parens */
/* eslint-disable import/prefer-default-export */

const initialState = {
  data: undefined,
  res: undefined,
  error: null,
  success: false,
  setDataSuccess: false,
  loading: false,
};

export const register = (namespace) => (state = initialState, action) => {
  switch (action.type) {
    case `${namespace}/REGISTER_REQUEST`: {
      return {
        ...state,
        data: null,
        res: null,
        error: null,
        success: false,
        loading: true,
      };
    }
    case `${namespace}/REGISTER_SUCCESS`: {
      return {
        ...state,
        data: action.data,
        res: action.res,
        error: null,
        success: true,
        loading: false,
      };
    }
    case `${namespace}/REGISTER_FAILURE`: {
      return {
        ...state,
        data: null,
        res: null,
        error: action.error,
        success: false,
        loading: false,
      };
    }
    default:
      return state;
  }
};
