/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/static-property-placement */
/* eslint-disable no-trailing-spaces */

/* eslint max-len: ["error", { "code": 300 }] */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
/* eslint-disable arrow-parens */
/* eslint-disable no-shadow */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable  react/forbid-prop-types */
/* eslint-disable  react/no-did-update-set-state */
/* eslint-disable  react/jsx-boolean-value */
/* eslint-disable  no-param-reassign */
/* eslint-disable  vars-on-top */
/* eslint-disable  no-var */
/* eslint-disable  curly */
/* eslint-disable  nonblock-statement-body-position */

import React, { PureComponent, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Button } from 'reactstrap';
import ReactSelect from 'react-select';
import { rules } from '../validate';
import renderSelectField from './Select';
import SelectSection from './SelectSection';
import SingleMultiSelectSection from './SingleMultiSelectSection';

function preventNonNumericalInput(e) {
  e = e || window.event;
  var charCode = (typeof e.which === 'undefined') ? e.keyCode : e.which;
  var charStr = String.fromCharCode(charCode);

  if (!charStr.match(/^[0-9]+$/))
    e.preventDefault();
}

export const RenderFieldInput = (props) => {
  const {
    input, maxLength, min, max, placeholder, type, isDisabled, meta: { touched, error },
  } = props;
  const [selectionStart, setSelectionStart] = useState(0);
  const inputRef = useRef();
  return (
    <div className="form__form-group-input-wrap">
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {type === 'number' ?
          <input {...input} className="input_class" placeholder={placeholder} type={type} disabled={isDisabled} maxLength={maxLength} min={min} max={max} pattern="[0-9]*" onKeyPress={preventNonNumericalInput} />
          :
          <input ref={inputRef} className="input_class" {...input} placeholder={placeholder} type={type} disabled={isDisabled} maxLength={maxLength} min={min} max={max} />}
      </div>
      {touched && error && <span className="form__form-group-error">{error}</span>}
    </div>
  );
};
export const RenderTextAreaFieldInput = (props) => {
  const {
    input, maxLength, min, max, name, placeholder, type, isDisabled, meta: { touched, error },
  } = props;
  return (
    <div className="form__form-group-input-wrap">
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <textarea name={name} className="input_class" {...input} placeholder={placeholder} type={type} disabled={isDisabled} maxLength={maxLength} min={min} max={max} rows={4} />
      </div>
      {touched && error && <span className="form__form-group-error">{error}</span>}
    </div>
  );
};
export const RenderSelectInput = (props) => {
  const {
    input, isMultiple, isDisabled, options, meta: { touched, error }, initialValues, value,
  } = props;
  return (
    <div className="form__form-group-input-wrap">
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <select {...input} style={{ width: '100%', padding: '0.25rem' }} disabled={isDisabled} multiple={isMultiple} size={isMultiple ? (options && options?.length >= 5 ? '6' : options?.length + 1) : false}>
          <option value={null} className="py-1">Seçiniz...</option>
          {options && options.map((option, index) => <option className="py-1" selected={option.selected === true} value={options[index].trText}>{options[index].trText}</option>)}
        </select>
      </div>
      {isMultiple && 
        <span className="form__form-group-character" style={{ fontSize: '11px' }}>
          Çoklu seçim yapabilirsiniz
        </span>}

      {touched && error && <span className="form__form-group-error">{error}</span>}
    </div>
  );
};

class CustomInputFormSection extends PureComponent {
  static propTypes = {
    mode: PropTypes.oneOf(['create', 'edit', 'view']).isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    validate: PropTypes.array,
    maxLength: PropTypes.number,
    lowerCaseBool: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    errorMsg: PropTypes.string,
  };

  static defaultProps = {
    isDisabled: false,
    validate: [],
    maxLength: undefined,
    lowerCaseBool: false,
    min: undefined,
    max: undefined,
    errorMsg: undefined,
  };

  componentDidMount() {
    const { name, changeFieldValue, defaultValue } = this.props;
    if (defaultValue) {
      changeFieldValue(name, defaultValue);
    }
  }

  getLayeredObjectValue = (object, target) => {
    const split = target.split('.');
    let returnVal = object;
    for (let i = 0, len = split.length; i < len; i += 1) {
      returnVal = returnVal[split[i]] || false;
    }
    return returnVal || false;
  }

convertOptions = (options) => {
  const newOptions = [];
  options.forEach((option) => {
    newOptions.push({
      value: option.trText,
      label: option.trText,
      selected: option.selected,
      id: option.trText,
    });
  });
  return newOptions;
}

render() {
  const { mode, value, parse, maxLength, label, placeholder, name, type, errorMsg, options, onChange, isDisabled, validate, lowerCaseBool, min, max, style, pattern, requiredStarBool, changeFieldValue } = this.props;
  // const capitalize = (value) => value && value.toLocaleUpperCase('tr-TR');
  return (
    <div className="form__form-group" style={style}>
      {errorMsg && <div className="text-danger mt-3 fs-12">{errorMsg}</div>}
      <span className="form__form-group-label">{requiredStarBool && '*'}{label}
      </span>
      <div className="form__form-group-field">
        {type === 'SHORT_TEXT' ?
          <Field
            name={name}
            component={RenderFieldInput}
            type={type}
            placeholder={placeholder}
            isDisabled={isDisabled}
            lowerCaseBool={lowerCaseBool}
            validate={validate && validate.map(rule => rules[rule])}
            onChange={onChange}
            maxLength={maxLength}
            min={min}
            max={max}
            style={{ width: '100%' }}
            pattern={pattern}
            parse={parse}
            value={value}
          />
          : type === 'INTEGER' ?
            <Field
              name={name}
              component={RenderFieldInput}
              placeholder={placeholder}
              isDisabled={isDisabled}
              lowerCaseBool={lowerCaseBool}
              validate={validate && validate.map(rule => rules[rule])}
              onChange={onChange}
              maxLength={maxLength}
              min={min}
              type="number"
              max={max}
              style={{ width: '100%' }}
              pattern={pattern}
              parse={parse}
              value={value}
            /> :
            type === 'LONG_TEXT' ?
              <Field
                name={name}
                component={RenderTextAreaFieldInput}
                placeholder={placeholder}
                isDisabled={isDisabled}
                lowerCaseBool={lowerCaseBool}
                validate={validate && validate.map(rule => rules[rule])}
                onChange={onChange}
                maxLength={maxLength}
                min={min}
                max={max}
                style={{ width: '100%' }}
                pattern={pattern}
                parse={parse}
                value={value}
              /> :
              type === 'SELECT' ?
                <SelectSection
                  name={name}
                  placeholder={placeholder}
                  options={this.convertOptions(options)}
                  validate={validate}
                  defaultValue={this.convertOptions(options) && this.convertOptions(options).filter(option => option.selected === true)[0]}
                    // initialValues={initialValues}
                  changeFieldValue={changeFieldValue}
                  disabled={isDisabled}
                />
                :
                type === 'MULTI_SELECT' ?
                  <SingleMultiSelectSection
                    name={name}
                    placeholder={placeholder}
                    maxMenuHeight={200}
                    options={this.convertOptions(options)}
                    validate={validate}
                    disabled={isDisabled} 
                    defaultValue={this.convertOptions(options) && this.convertOptions(options).filter(option => option.selected === true)}
                    changeFieldValue={changeFieldValue}

                  />
                  :
                  ''}
      </div>
      {mode !== 'view' && maxLength &&
      <span className="form__form-group-character" style={{ fontSize: '11px' }}>
        En fazla {maxLength} karakter
      </span>}
    </div>
  );
}
}

export default CustomInputFormSection;
