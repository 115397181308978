/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Alert, AlertTitle } from '@mui/material';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Card, CardBody, Col, Collapse, Row,
} from 'reactstrap';
import { getAction } from '../../redux/actions/getAction';
import { list } from '../../redux/actions/listAction';
import { addNotification } from '../../redux/actions/notificationActions';
import { putAction } from '../../redux/actions/putAction';
import { save } from '../../redux/actions/saveAction';
import Table from '../../shared/components/generic-table/Table';
import GenericModal from '../../shared/components/GenericModal';
import { memberTypeLookup } from '../App/helper/statusTableLookupValues';
import AddTeamMemberForm from './components/AddTeamMemberForm';
import RequiredConditionsForNST from './components/RequiredConditionsForNST';

class NutritionalSupportTeam extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowId: undefined,
      prevSelectedRowId: undefined,
      isModalOpen: false,
    };
  }

  componentDidMount() {
    const { fetchTableData, validateNutritionTeam } = this.props;
    fetchTableData();
    validateNutritionTeam();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      hospitalNutritionSaveSuccess, fetchTableData, validateNutritionTeam,
      hospitalNutritionUpdateSuccess, nutritionMemberDeleteSuccess,
    } = this.props;
    const { selectedRowId } = this.state;
    if (prevState.selectedRowId !== selectedRowId && selectedRowId) {
      this.toggle();
    }
    if ((prevProps.hospitalNutritionSaveSuccess !== hospitalNutritionSaveSuccess && hospitalNutritionSaveSuccess)
    || (prevProps.hospitalNutritionUpdateSuccess !== hospitalNutritionUpdateSuccess && hospitalNutritionUpdateSuccess)) {
      fetchTableData();
      validateNutritionTeam();
      this.setState({ isModalOpen: false });
    }
    if (prevProps.nutritionMemberDeleteSuccess !== nutritionMemberDeleteSuccess && nutritionMemberDeleteSuccess) {
      fetchTableData();
      validateNutritionTeam();
      this.setState({ isModalOpen: false });
    }
  }

  setSelectedRow = (rowData) => {
    const { pagePrivileges } = this.props;
    if (!rowData) {
      // undefined: form is closed, null: form is open and create mode, {data}: edit mode
      this.setState({
        selectedRowId: rowData === undefined ? undefined : null,
      });
    } else {
      this.setState({
        selectedRowId: rowData.id,
        prevSelectedRowId: rowData.id,
      });
    }
  };

  handleSubmit = (values) => {
    const { saveNutritionMember, updateNutritionMember } = this.props;
    if (values.id) {
      updateNutritionMember(values);
    } else {
      saveNutritionMember(values);
    }
  }

  toggle = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  }

  render() {
    const { nutritionMemberValidate, initialValues } = this.props;
    const {
      isModalOpen, selectedRowId, prevSelectedRowId,
    } = this.state;
    return (
      <Card>
        <CardBody className="p-0 p-md-2">
          <Row>
            <div>

              <Alert className="mb-3" severity={nutritionMemberValidate?.criteriaList?.filter((el) => el.provided === false).length === 0 ? 'success' : 'error'} style={{ color: nutritionMemberValidate?.criteriaList?.filter((el) => el.provided === false).length === 0 ? 'rgb(32 95 61)' : 'rgb(95, 33, 32)', backgroundColor: nutritionMemberValidate?.criteriaList?.filter((el) => el.provided === false).length === 0 ? 'rgb(237 253 237)' : 'rgb(253, 237, 237)' }}>
                {nutritionMemberValidate?.criteriaList?.filter((el) => el.provided === false).length === 0
                  ? <AlertTitle>Başvuru oluşturabilmek için gerekli şartlar sağlandı.</AlertTitle>
                  : (
                    <>
                      <AlertTitle>Başvuru oluşturabilmek için gerekli şartlar sağlanmadı.</AlertTitle>
                      <AlertTitle>&apos;Yeni Üye&apos; butonuna tıklayarak nütrisyon destek ekibinize gerekli üyeleri eklemeniz gerekmektedir.</AlertTitle>
                    </>
                  )}

                <ul className="mb-0" style={{ listStyle: 'none' }}>
                  {nutritionMemberValidate?.criteriaList?.map((item) => (
                    <li className={item.provided ? 'text-success' : 'text-danger'}>{item.provided ? <i className="lnr lnr-checkmark-circle text-success" /> : <i className="lnr lnr-cross-circle text-danger" />} {item.trDescription}</li>
                  ))}
                </ul>
              </Alert>
            </div>

            <Table
              data={initialValues}
              setSelectedRow={this.setSelectedRow}
              selectedRowId={selectedRowId}
              prevSelectedRowId={prevSelectedRowId}
              newButtonBool
              newButtonText="Yeni Üye"
              newButtonOnClick={this.toggle}
              columns={[
                { id: 1, title: 'Unvanı', field: 'title' },
                { id: 2, title: 'Adı', field: 'name' },
                { id: 3, title: 'Soyadı', field: 'surname' },
                {
                  id: 3, title: 'Üye Tipi', field: 'memberType', lookup: memberTypeLookup,
                },
                { id: 3, title: 'E-posta', field: 'email' },
              ]}
              maxBodyHeight={false}
            />
            <GenericModal
              isModalOpen={isModalOpen}
              button={<Button className="d-none my-2" style={{ width: 0 }} color="success" />}
              toggleModal={this.toggle}
              onClosed={() => this.setSelectedRow(null)}
              content={(
                <AddTeamMemberForm
                  onSubmit={this.handleSubmit}
                  id={selectedRowId}
                  toggle={this.toggle}
                />
              )}
            />
          </Row>
        </CardBody>
      </Card>

    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchTableData() {
    dispatch(list('hospital/nutrition/member'));
  },
  saveNutritionMember(values) {
    dispatch(save('hospital/nutrition/member', values));
  },
  updateNutritionMember(values) {
    dispatch(putAction('hospital/nutrition/member/update', values));
  },
  validateNutritionTeam() {
    dispatch(getAction('hospital/nutrition/member/check'));
  },
});

const mapStateToProps = (state, ownProps) => ({
  initialValues: state.hospitalNutritionList,
  hospitalNutritionSaveSuccess: state.hospitalNutritionSave.success,
  hospitalNutritionUpdateSuccess: state.hospitalNutritionUpdate.success,
  nutritionMemberValidate: state.nutritionMemberValidate.data,
  nutritionMemberDeleteSuccess: state.nutritionMemberDelete.success,

});

export default connect(mapStateToProps, mapDispatchToProps)(NutritionalSupportTeam);
