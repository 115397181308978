/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/static-property-placement */
/* eslint-disable jsx-a11y/label-has-for,guard-for-in */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/sort-comp */
/* eslint max-len: ["error", { "code": 400 }] */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable arrow-parens */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable no-class-assign */
/* eslint-disable object-shorthand */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-confusing-arrow */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable prefer-template */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-else-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable  react/self-closing-comp */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable dot-notation */
/* eslint-disable lines-between-class-members */
/* eslint-disable object-curly-spacing */
/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import {
  Col, ButtonToolbar, Button, Row,
} from 'reactstrap';
import { reduxForm, formValueSelector, Field } from 'redux-form';
import { connect } from 'react-redux';
import CustomInputFormSection from '../../../shared/components/generic-form/FormSections/SingleSections/CustomInputFormSection';
import GenericAccordion from '../../../shared/components/GenericAccordion';
import InputSection from '../../../shared/components/generic-form/FormSections/SingleSections/InputSection';

class NewApplicationForm extends PureComponent {
  componentDidMount() {
    const { tcNo, getByTc } = this.props;
  }

  componentDidUpdate(prevProps) {
    const { initialValues, initialize, tcNo, id, info, getByTc } = this.props;
  }

  render() {
    const {
      pristine, submitting, toggle, handleSubmit, title, data, change, initialValues,
    } = this.props;
    console.log(initialValues);
    return (
      <Col md={12} lg={12}>
        <h5>{title}</h5>
        <form className="form form--horizontal px-2">
          {data && data.length > 0 && data.map((item) => (

            <>
              <CustomInputFormSection
                label={item.text}
                // isDisabled={initialValues && initialValues.status === 'SENT'}
                isDisabled={(initialValues && initialValues.status && !((initialValues.status === 'NEW') || (initialValues.status === 'APPEAL_REVISION' && (item.answerDto && item.answerDto.appealAnswerReviewDtoList && item.answerDto.appealAnswerReviewDtoList.length > 0))))}
                name={'questionId=' + item.id}
                validate={item.required ? ['required'] : ''}
                requiredStarBool={item.required}
                defaultValue={item && item.answerDto && item.answerDto.answerText}
                // defaultSelectValue={item?.choicesDtoList && item.choicesDtoList.find((choice) => choice.selected === true)}
                changeFieldValue={change}
                style={{ marginBottom: '1rem' }}
                type={item && item.type}
                options={item.choicesDtoList}
                maxLength={item.maxCharacter}
              />
              {item.answerDto && item.answerDto.appealAnswerReviewDtoList && item.answerDto.appealAnswerReviewDtoList &&
              <Col xs={12} className="mt-2 text-danger p-0">
                <div><i className="fa fa-exclamation-circle" /> {item?.answerDto?.appealAnswerReviewDtoList[0]?.reviewTime} - {item?.answerDto?.appealAnswerReviewDtoList[0]?.description}</div>
              </Col>}
            </>
          ))}
          <ButtonToolbar style={{ justifyContent: 'space-evenly', marginTop: '20px' }}>
            <>
              <Row className="w-100 mt-2">
                <Col xs={12} sm={6} className="mb-2 text-center">
                  <Button style={{ width: '180px' }} type="button" onClick={handleSubmit} disabled={pristine || submitting || initialValues.status === 'SENT'} color="success" size="sm">Kaydet</Button>
                </Col>
              </Row>
            </>
          </ButtonToolbar>
        </form>
      </Col>
    );
  }
}

const mapDispatchToProps = () => {
  return {
  };
};
const mapStateToProps = () => ({
});

NewApplicationForm = reduxForm({
  form: 'New_Application_Form', // a unique identifier for this form
  // enableReinitialize: true,
  // onSubmitFail: (errors) => scrollToFirstError(errors),
})(NewApplicationForm);

export default connect(mapStateToProps, mapDispatchToProps)(NewApplicationForm);
