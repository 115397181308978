/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint max-len: ["error", { "code": 300 }] */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable camelcase */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable object-curly-newline */
/* eslint-disable max-len */

import React, { PureComponent } from 'react';
import { reduxForm } from 'redux-form';
import {
  Button, Col, Row, UncontrolledTooltip,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { renderLoginFieldInput } from '../../../shared/components/generic-form/fieldRenders';
import SelectSection from '../../../shared/components/generic-form/FormSections/SingleSections/SelectSection';
import PdfUploadFormSection from '../../../shared/components/generic-form/FormSections/PdfUploadFormSection';
import LoginInputSection from '../../../shared/components/generic-form/FormSections/SingleSections/LoginInputSection';

class RegisterForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidUpdate(prevProps) {
    const { hospitalRegisterSuccess, initialize } = this.props;

    if (prevProps.hospitalRegisterSuccess !== hospitalRegisterSuccess && hospitalRegisterSuccess) {
      initialize(null);
    }
  }

  render() {
    const {
      handleSubmit, cityList, onCityChange, onChangePdf, countyList, initialValues, hospitalRegisterLoading,
    } = this.props;
    return (
      <>
        <form className="form mt-1" onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              <LoginInputSection
                name="title"
                placeholder="Hastane Adı"
                type="text"
                customClassName="fadeIn second loginput form-control"
                validate={['required']}
                initialValues={initialValues}
                disabled={hospitalRegisterLoading}
              />
            </Col>
            <Col xs={6}>
              <LoginInputSection
                name="authorityName"
                placeholder="Yetkili Kişi Adı"
                type="text"
                customClassName="fadeIn second loginput form-control"
                validate={['required']}
                initialValues={initialValues}
                disabled={hospitalRegisterLoading}

              />
            </Col>
            <Col xs={6}>
              <LoginInputSection
                name="authoritySurname"
                placeholder="Yetkili Kişi Soyadı"
                type="text"
                customClassName="fadeIn second loginput form-control"
                validate={['required']}
                initialValues={initialValues}
                disabled={hospitalRegisterLoading}

              />
            </Col>
            <Col xs={6}>
              <LoginInputSection
                name="authorityDuty"
                component={renderLoginFieldInput}
                placeholder="Yetkili Kişi Görevi"
                type="text"
                customClassName="fadeIn second loginput form-control"
                validate={['required']}
                initialValues={initialValues}
                disabled={hospitalRegisterLoading}

              />
            </Col>
            <Col xs={6}>
              <LoginInputSection
                name="authorityEmail"
                component={renderLoginFieldInput}
                placeholder="Yetkili Kişi E-Posta"
                type="text"
                customClassName="fadeIn second loginput form-control"
                validate={['required']}
                initialValues={initialValues}
                disabled={hospitalRegisterLoading}

              />
            </Col>
            <Col xs={6}>
              <SelectSection
                name="countyDto.cityDto.id"
                placeholder="Şehir"
                onChange={onCityChange}
                style={{ height: '26px' }}
                options={cityList && cityList.map((el) => ({ value: el.id, label: el.name }))}
                isRegisterField
                validate={['required']}
                initialValues={initialValues}
                disabled={hospitalRegisterLoading}

              />
            </Col>
            <Col xs={6}>
              <SelectSection
                placeholder="İlçe"
                name="countyDto.id"
                options={countyList && countyList.map((el) => ({ value: el.id, label: el.name }))}
                isRegisterField
                validate={['required']}
                initialValues={initialValues}
                disabled={hospitalRegisterLoading}

              />
            </Col>
            <Col xs={12} id="authorityFile">
              <PdfUploadFormSection
                name="document"
                label="Yetki Belgesi"
                labelStyle={{ fontSize: '12px' }}
                onChange={(e) => onChangePdf(e)}
                isRegisterField
                validate={['required']}
                initialValues={initialValues}
                disabled={hospitalRegisterLoading}
                downloadDocument={this.downloadDocument}
              />

            </Col>
            <UncontrolledTooltip fade delay={{ show: 100, hide: 100 }} placement="bottom" target="authorityFile">
              KEPAN Akreditasyon Yönetim Sistemine üyelik sürecinizin tamamlanması için, "Örnek Yetkilendirme Belgesi" linkinden indireceğiniz belgenin, kurum yetkilisi tarafından imzalanmış ve kaşelenmiş halini taratıp PDF formatında yüklemeniz gerekmektedir.
            </UncontrolledTooltip>
          </Row>
          <Button
            className="fadeIn fourth btn-login bg-custom-primary underlineHover mt-4"
            disabled={hospitalRegisterLoading}
            type="submit"
          >Kayıt Ol
          </Button>
        </form>

      </>
    );
  }
}

export default reduxForm({
  form: 'Register_Form',
})(withRouter(RegisterForm));
