/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-parens */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-plusplus */

const initialState = {
  loading: false,
  data: undefined,
  error: null,
};

export const infoReducer = (namespace) => (state = initialState, action) => {
  switch (action.type) {
    case `${namespace}/INFO_REQUEST`: {
      return {
        ...state,
        loading: true,
        data: undefined,
        error: null,
      };
    }
    case `${namespace}/INFO_SUCCESS`: {
      return {
        ...state,
        data: action.data.resultContainer,
        loading: false,
      };
    }
    case `${namespace}/INFO_FAILURE`: {
      return {
        ...state,
        error: action.error,
        loading: false,
        data: undefined,
      };
    }
    default:
      return state;
  }
};
