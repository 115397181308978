/* eslint-disable react/prefer-stateless-function */
/* eslint-disable padded-blocks */
/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-distracting-elements */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable arrow-parens */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/tabindex-no-positive */

import React, { PureComponent } from 'react';

class Dashboard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      timeInterval: 'THIS_MONTH',
    };
  }

  changeInterval = (newTimeInterval) => {
    this.setState({ timeInterval: newTimeInterval });
  }

  render() {
    const { timeInterval } = this.state;
    return (
      <div className="text-center">
        Anasayfa
      </div>
    );
  }
}

export default Dashboard;
