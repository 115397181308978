/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  Button, ButtonToolbar, Card, CardBody, Col, Modal, ModalBody, Row,
} from 'reactstrap';
import { Chip } from '@material-ui/core';
import GenericModal from '../../../shared/components/GenericModal';
import SelectAvailableDates from '../compontents/SelectAvailableDates';
import AddReportForm from '../compontents/AddReportForm';
import PdfUploadFormSection from '../../../shared/components/generic-form/FormSections/PdfUploadFormSection';
import { renderFileField } from '../../../shared/components/generic-form/fieldRenders';
import { appealStatus } from '../../App/helper/statusTableLookupValues';
import AddExtraDocument from '../compontents/AddExtraDocument';
import UploadPdfWithNameForm from '../compontents/UploadPdfWithNameForm';
import LoadingSpinner from '../../../shared/components/form/LoadingSpinner';
import ornekBelge from '../../../shared/doc/ornek-basvuru-belgesi.docx';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function NewApplicationFormTabs(props) {
  const { setValue, value } = props;
  const [isModalOpen, toggle] = React.useState(false);
  const tablist = props.initialValues && props.initialValues.appealFormDtoList;

  React.useEffect(() => {
    if (props.initialValues.status === 'READY_TO_VISIT'
     || props.initialValues.status === 'SENT'
     || props.initialValues.status === 'REJECTED'
     || props.initialValues.status === 'COUNCIL_ASSIGNED'
     || props.initialValues.status === 'REVISIT_REQUESTED'
     || props.initialValues.status === 'REVISION_REPORT_REQUESTED'
     || props.initialValues.status === 'VISIT_REVIEW') {
      setValue(tablist.length);
    }
  }, [props.initialValues.status]);

  const handleChange = (event, newValue) => {
    if (event) {
      setValue(newValue);
    }
  };
  const routeToNst = () => {
    const { history } = props;
    history.push('/NST');
  };
  const submitValues = (values) => {
    const { onSubmitValues, initialValues } = props;
    delete initialValues.appealTime;
    delete initialValues.status;
    delete initialValues.hospitalDto;
    initialValues.appealFormDtoList.forEach((forms, formIndex) => forms.questionDtoList.forEach((questions, questionIndex) => {
      if (questions.answerDto && questions.answerDto.answerTime) {
        delete initialValues.appealFormDtoList[formIndex].questionDtoList[questionIndex].answerDto.answerTime;
      }
    }));
    Object.entries(values).forEach(([key, val]) => {
      // eslint-disable-next-line no-lone-blocks
      if (key.startsWith('question')) {
        if (initialValues.appealFormDtoList[value].questionDtoList.filter((item) => item.id === parseInt(key.split('questionId=')[1], 10))[0].type === 'MULTI_SELECT') {
          val.forEach((selectedAnswer) => {
            initialValues.appealFormDtoList[value].questionDtoList.filter((item) => item.id === parseInt(key.split('questionId=')[1], 10))[0].choicesDtoList.filter((item) => (item.trText === selectedAnswer.label || item.enText === selectedAnswer.label)).forEach((selecteds, index) => {
              initialValues.appealFormDtoList[value].questionDtoList.filter((item) => item.id === parseInt(key.split('questionId=')[1], 10))[0].choicesDtoList.filter((item) => (item.trText === selectedAnswer.label || item.enText === selectedAnswer.label))[0].selected = true;
            });
            initialValues.appealFormDtoList[value].questionDtoList.filter((item) => item.id === parseInt(key.split('questionId=')[1], 10))[0].choicesDtoList.filter((item) => !(item.trText === selectedAnswer.label || item.enText === selectedAnswer.label)).forEach((unselected, index) => {
              initialValues.appealFormDtoList[value].questionDtoList.filter((item) => item.id === parseInt(key.split('questionId=')[1], 10))[0].choicesDtoList.filter((item) => !((item.trText === selectedAnswer.label || item.enText === selectedAnswer.label)))[0].selected = false;
            });
            delete initialValues.appealFormDtoList[value].questionDtoList.filter((item) => item.id === parseInt(key.split('questionId=')[1], 10))[0].answerDto;
          });
        } else if (initialValues.appealFormDtoList[value].questionDtoList.filter((item) => item.id === parseInt(key.split('questionId=')[1], 10))[0].type === 'SELECT') {
          initialValues.appealFormDtoList[value].questionDtoList.filter((item) => item.id === parseInt(key.split('questionId=')[1], 10))[0].choicesDtoList.filter((item) => item.trText === val || item.enText === val || item.trText === val.label || item.enText === val.label)[0].selected = true;
          initialValues.appealFormDtoList[value].questionDtoList.filter((item) => item.id === parseInt(key.split('questionId=')[1], 10))[0].choicesDtoList.filter((item) => !(item.trText === val || item.enText === val || item.trText === val.label || item.enText === val.label)).forEach((unselected, index) => {
            initialValues.appealFormDtoList[value].questionDtoList.filter((item) => item.id === parseInt(key.split('questionId=')[1], 10))[0].choicesDtoList.filter((item) => !(item.trText === val || item.enText === val || item.trText === val.label || item.enText === val.label))[index].selected = false;
          });
          delete initialValues.appealFormDtoList[value].questionDtoList.filter((item) => item.id === parseInt(key.split('questionId=')[1], 10))[0].answerDto;
        } else {
          initialValues.appealFormDtoList[value].questionDtoList.filter((item) => item.id === parseInt(key.split('questionId=')[1], 10))[0].answerDto = { answerText: val };
        }
      }
    });
    onSubmitValues(initialValues);
  };

  const toggleModal = () => {
    toggle(!isModalOpen);
  };
  const onSubmitDates = (values) => {
    props.onSubmitAvailableDates(props.initialValues.id, { startDate: values.startDate, endDate: values.endDate });
    // props.onSubmitAvailableDates(props.initialValues.id, { });
  };
  const onSubmitAppeal = (values) => {
    props.appealSendReview(props.initialValues.id);
  };

  const sortData = (data) => data.sort((a, b) => {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  });
  const {
    component: ChildComponent, title, initialValues, downloadAttachmentDocument, deleteAppeal, formLoading,
    appealDocumentAttachmentList, cancelAppeal, onChangePdf, downloadDocument, deleteAttachmentDocument,
  } = props;
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="label">
          {tablist && tablist.map((tab, index) => (
            <Tab label={tab.tab_name || tab.title.slice(0, 8)} className="px-2" {...a11yProps(index)} />
          ))}
          <Tab label="Durum" className="px-2" {...a11yProps((tablist && tablist.length) || -1)} />

        </Tabs>
      </Box>
      {!formLoading ? (
        <>
          {tablist && tablist.map((tab, index) => (
            <TabPanel value={value} index={index}>
              <div className="mb-4">
                <h5 className="mb-0">{tablist[index].title}</h5>
                <span style={{ fontSize: '14px' }}>{tablist[index].description}</span>
              </div>
              <ChildComponent
                data={sortData(tab.questionDtoList)}
                title={title}
              // nextPage={nextPage}
                onSubmit={submitValues}
                initialValues={initialValues && initialValues}
              />
            </TabPanel>
          ))}
          <TabPanel value={value} index={tablist && tablist.length}>
            <div className="d-flex justify-content-between mb-3">
              <h5>Başvuru Durumu: {appealStatus[initialValues.status]}</h5>
            </div>
            {initialValues.status === 'REJECTED' && (
            <div>
              Reddetme Gerekçesi: {initialValues.rejectMessage}
            </div>
            )}
            {initialValues.status === 'CONFIRMED' && (
            <div>
              <p>
                Karar Metni: {initialValues.decisionDescription}
              </p>
              <p>Karar Tarihi: {initialValues.confirmDate}</p>
            </div>
            )}
            <hr />

            {(initialValues.status === 'NEW' || initialValues.status === 'APPEAL_REVISION' || initialValues.status === 'CONFIRMED') && (
            <>
              <div>
                {initialValues && initialValues.nutritionMemberRevisionMessage && initialValues.status !== 'CONFIRMED' && (
                <Col xs={12} className="mt-2 text-danger p-0">
                  <h6>
                    Nütrisyon Ekibi Revizyon Mesajı: <br />
                    {initialValues.nutritionMemberRevisionMessage} <br />
                  </h6>
                  <Button size="sm" className="btn-custom-info" onClick={() => routeToNst()}> Nütrisyon Ekibini
                    Düzenle
                  </Button>
                </Col>
                )}
              </div>
              <hr />

              <div className="my-4">
                <h5>Başvuru Dilekçesi</h5>
                {initialValues.hasAppointmentFile ? (
                  <div className="my-3">
                    <Button onClick={() => downloadDocument(initialValues.id)} color="info" size="sm" className="mt-2">Başvuru
                      Dilekçesini Görüntüle
                    </Button>
                  </div>
                )
                  : 'Başvuru dilekçesi yüklenmedi'}
                {
                  initialValues.status !== 'CONFIRMED'
                  && (
                    <>
                      <h6>
                        {initialValues.hasAppointmentFile ? 'Başvuru Dilekçenizi Değiştirin'
                          : 'Başvuru Dilekçenizi Ekleyin. Dosyayı seçip Aç butonuna tıklayın. "Aç" butonuna tıkladığınızda belge otomatik olarak yüklenecektir. Sonrasında yüklediğiniz belgeyi görüntüleyebilir veya değiştirebilirsiniz.'}
                      </h6>
                      <input
                        name="document"
                        component={renderFileField}
                        type="file"
                        accept="application/pdf"
                        onChange={(e) => onChangePdf(e, initialValues.id)}
                      />
                    </>
                  )
                }
                <div>
                  <a href={ornekBelge} className="btn-custom-primary px-2 py-1 rounded" target="_blank" rel="noopener noreferrer">Örnek Başvuru Dilekçesi İndir</a>
                </div>
              </div>
              <hr />
              {appealDocumentAttachmentList.length > 0 && (
              <div className="mt-4">
                <h5>Ek Belgeler</h5>
                <Row className="mt-3">
                  {appealDocumentAttachmentList.map((item, index) => (
                    <Col xs={12} lg={4} className="mb-3 pb-2">
                      <h6>{item.fileName}</h6>
                      <div>
                        <Button
                          onClick={() => downloadAttachmentDocument(initialValues.id, item.id)}
                          className="mx-2"
                          color="outline-success"
                          size="sm"
                        >Belgeyi İndir
                        </Button>
                        {initialValues.status !== 'CONFIRMED' && (
                        <Button
                          onClick={() => deleteAttachmentDocument(initialValues.id, item.id)}
                          className="mx-2"
                          color="outline-danger"
                          size="sm"
                        >Sil
                        </Button>
                        )}
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
              )}
              {initialValues.status !== 'CONFIRMED'
                && (
                  <>
                    <GenericModal
                      isModalOpen={props.isExtraDocModalOpen}
                      button={(
                        <Button
                          className="btn-custom-primary btn-sm"
                          disabled={appealDocumentAttachmentList.length >= 5}
                          style={{ verticalAlign: 'bottom' }}
                          onClick={props.toggleExtraDocModal}
                          type="button"
                          color="info"
                        >Ek Belge Yükle
                        </Button>
                      )}
                      size="lg"
                      modalTitle="Ek belge yükle"
                      toggleModal={props.toggleExtraDocModal}
                      content={(
                        <UploadPdfWithNameForm
                          onSubmit={props.onSubmitExtraDocument}
                          togglePdfModal={props.toggleExtraDocModal}
                        />
                      )}
                    />
                    <span className="ms-2" style={{ fontSize: '12px' }}>*En fazla 5 ek belge yükleyebilirsiniz.</span>
                    <hr />
                    <div className="mt-4">
                      Tüm Bilgilerin doğruluğundan eminseniz başvuruyu onaylayın.
                      <br />
                      <GenericModal
                        isModalOpen={props.isModalOpen}
                        button={<Button color="success" size="sm" className="mt-2 me-3"> Onayla ve Gönder</Button>}
                        toggleModal={props.toggle}
                        content={(
                          <div>
                            {/* <SelectAvailableDates toggle={props.toggle} initialValues={initialValues} onSubmit={onSubmitDates} /> */}
                            <div className="mb-5">
                              <h5>Başvuruyu onaylamak istediğinize emin misiniz?</h5>
                            </div>
                            <ButtonToolbar style={{ justifyContent: 'space-around' }}>
                              <Button
                                style={{ width: '80px' }}
                                onClick={() => props.toggle()}
                                color="secondary"
                              >Vazgeç
                              </Button>
                              <Button
                                style={{ width: '80px' }}
                                type="button"
                                onClick={() => onSubmitAppeal()}
                                color="success"
                              >Onayla
                              </Button>
                            </ButtonToolbar>
                          </div>
                        )}
                      />
                      {initialValues.status === 'NEW'
                        ? (
                          <Button
                            onClick={() => deleteAppeal(initialValues.id)}
                            color="danger"
                            size="sm"
                            className="mt-2"
                          >Sil
                          </Button>
                        )

                        : (
                          <Button
                            onClick={() => cancelAppeal(initialValues.id)}
                            color="danger"
                            size="sm"
                            className="mt-2"
                          >İptal Et
                          </Button>
                        )}
                    </div>
                  </>
                )}

            </>
            )}
            {initialValues.status === 'SENT' && (
            <>
              Başvurunuz Değerlendiriliyor. Değerlendirme bittiğinde bu
              ekrandan değerlendirme sonucunuzu görüntüleyebilirsiniz.
              <br />
            </>
            )}
            {initialValues.status === 'READY_TO_VISIT' && (
            <>
              Yerinde değerlendirme süreci devam ediyor.
            </>
            )}
            {((initialValues.status === 'WAITING_DATE_SELECTION') || initialValues.status === 'REVISIT_REQUESTED') && (
            <>
              <SelectAvailableDates
                title="Yerinde değerlendirme için kurumunuzun ziyarete uygun olduğu bir aylık tarih aralığı seçiniz."
                initialValues={initialValues}
                onSubmit={onSubmitDates}
              />
            </>
            )}
            {initialValues.status === 'WAITING_VISIT_PLANNING' && (
            <>
              Kepan akreditasyon yetkililerinin seçtiğiniz müsait tarihler arasından bir tarih seçmesi ve yerinde
              değerlendirme ekibini belirlemesi bekleniyor.
            </>
            )}

            {initialValues.status === 'COUNCIL_ASSIGNED' && (
            <>
              Heyet Ataması Yapıldı.
            </>
            )}
            {initialValues.status === 'REVISION_REPORT_REQUESTED' && (
            <div>
              Yerinde Değerlendirme Sekmesinden Belge Yükleyiniz
            </div>
            )}
            {initialValues.status === 'VISIT_REVIEW' && (
            <div>Kurul üyelerinin yerinde değerlendirmeleri tamamlamaları bekleniyor. Yerinde değerlendirmeler
              sekmesinden ilgili başvurunun detaylarına erişebilirsiniz.
            </div>
            )}

          </TabPanel>
        </>
      ) : <LoadingSpinner />}

    </Box>
  );
}
