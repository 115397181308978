/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/static-property-placement */
/* eslint-disable arrow-parens */
/* eslint-disable no-shadow */
/* eslint-disable arrow-body-style */
/* eslint-disable object-shorthand */
/* eslint-disable react/prop-types */
/* eslint max-len: ["error", { "code": 400 }] */
/* eslint-disable no-unused-vars */
/* eslint-disable block-spacing */
/* eslint-disable no-lone-blocks */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable object-curly-newline */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
/* eslint-disable no-mixed-operators */
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { initialize, startSubmit } from 'redux-form';
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { getById } from '../../../redux/actions/byIdAction';
import RegisterRevisionRejectForm from './RegisterRevisionRejectForm';
import ChangePasswordForm from '../../ChangePassword/ChangePasswordForm';
import { putAction } from '../../../redux/actions/putAction';

class HospitalDetail extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    loginType: PropTypes.string.isRequired,
    setLoginActiveTab: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      showNewPassForm: false,
    };
  }

  componentDidMount() {
    const { id, fetchHospital } = this.props;
    if (id) {
      fetchHospital(id);
    }
  }

  componentDidUpdate(prevProps) {
    const { id, fetchHospital } = this.props;
    // if (prevProps.id !== id && id) {
    //   fetchHospital(id);
    // }
  }

  showTextArea = (type) => {
    if (type === 'revision') {
      this.setState({ showRevisionMessage: true, showRejectMessage: false });
    } else if (type === 'reject') {
      this.setState({ showRejectMessage: true, showRevisionMessage: false });
    } else {
      this.setState({ showRejectMessage: false, showRevisionMessage: false });
    }
  }

  onSubmit = (values) => {
    const { revisionRegister, rejectRegister, hospitalData } = this.props;
    const { showRevisionMessage, showRejectMessage } = this.state;
    if (showRevisionMessage) {
      revisionRegister(hospitalData.id, values);
    }
    if (showRejectMessage) {
      rejectRegister(hospitalData.id, values);
    }
  }

  toggleShowNewPassForm = () => {
    const { showNewPassForm } = this.state;
    this.setState({ showNewPassForm: !showNewPassForm });
  }

  setNewPass = (values) => {
    const { setPassword, hospitalData } = this.props;
    const id = hospitalData && hospitalData.authorizedUserDto && hospitalData.authorizedUserDto.id;
    const sendData = {
      newPassword: values.rePassword,
    };
    setPassword(sendData, id);
  }

  render() {
    const { hospitalData } = this.props;
    const { showNewPassForm } = this.state;
    return (
      <Card>
        <CardHeader><h4>Hastane Bilgileri</h4></CardHeader>
        <CardBody className="p-0 p-md-2">
          {hospitalData &&
          <Row>
            <Col xs={12}>
              <h5>{hospitalData.title}</h5>
              <i>
                {`${hospitalData.countyDto.cityDto.name} ${hospitalData.countyDto.name}`}
              </i>
              <hr />
            </Col>
            <Col xs={12} className="mb-3">
              Yetkili Kişi: {`${hospitalData.currentAuthority.name} ${hospitalData.currentAuthority.surname}`}
            </Col>
            <Col xs={12} className="mb-3">
              Yetkili Görevi: {`${hospitalData.currentAuthority.duty}`}
            </Col>
            <Col xs={12} className="mb-3">
              Yetkili E-Posta: {`${hospitalData.currentAuthority.email}`}
            </Col>
          </Row>}
          <Row className="mt-3">
            <Col className="text-end">
              <Button className="btn-custom-success" size="sm" onClick={() => this.toggleShowNewPassForm()}>
                Yeni Şifre Ata
              </Button>
            </Col>
            {showNewPassForm &&
            <Col xs={12}>
              <ChangePasswordForm onSubmit={this.setNewPass} />
            </Col>}
          </Row>
        </CardBody>

      </Card>

    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchHospital(id) {
      dispatch(getById('hospital', id));
    },
    setPassword(values, id) {
      dispatch(putAction('user/pass/change', values, id));
    },
  };
};

const mapStateToProps = (state) => ({
  hospitalData: state.getHospital.data,
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HospitalDetail));
