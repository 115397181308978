/* eslint-disable arrow-parens */
/* eslint-disable quotes */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable object-shorthand */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
/* eslint-disable no-lonely-if */
/* eslint-disable object-curly-newline */
/* eslint-disable no-prototype-builtins */

import axios from 'axios';
import { addNotification } from './notificationActions';
import { authFileHeader, authHeader } from '../../containers/App/helper/auth-header';
// import { userRolesControl, getUserId } from '../../containers/App/helper/auth-user-info';

function request(namespace) {
  return {
    type: `${namespace}/DOCUMENT_DOWNLOAD_REQUEST`,
  };
}

function success(namespace, data) {
  return {
    type: `${namespace}/DOCUMENT_DOWNLOAD_SUCCESS`,
    data,
  };
}

function failure(namespace, error) {
  return {
    type: `${namespace}/DOCUMENT_DOWNLOAD_FAILURE`,
    error,
  };
}
function reset(namespace) {
  return {
    type: `${namespace}/DOCUMENT_DOWNLOAD_RESET`,
  };
}


export function download(namespace, token, hasAuth, id2) {
  return dispatch => {
    if (token === "reset") {
      dispatch(reset(namespace));
    } else {
      dispatch(request(namespace));
      axios.get(`${process.env.REACT_APP_API_URL}/${namespace}/document/${token}${id2 ? `/${id2}` : ''}`, hasAuth ? authHeader() : '')
        .then(res => {
          if (res.data.errorCode) {
            dispatch(addNotification(res.data.errorCode, 'error'));
          } else {
            dispatch(success(namespace, res.data));
            const a = document.createElement('a'); // Create <a>
            a.href = `data:image/png;base64,${res.data.resultContainer.base64Data}`; // Image Base64 Goes here
            a.download = `${res.data.resultContainer.fileName}.${res.data.resultContainer.extension}`; // File name Here
            a.click();
          }
        })
        .then(() => {
        })
        .catch((error) => {
          dispatch(failure(namespace, error));
          if (error.response && error.response.data !== undefined) {
            if (error.response.data.errorMessage) {
              dispatch(addNotification(error.response.data.errorMessage, 'error'));
            }
          } else {
            dispatch(addNotification('500', 'error'));
          }
        });
    }
  };
}


export function downloadWithId(namespace, id1, id2) {
  return dispatch => {
    if (id1 === "reset") {
      dispatch(reset(namespace));
    } else {
      dispatch(request(namespace));
      axios.get(`${process.env.REACT_APP_API_URL}/${namespace}/download/${id1}${id2 ? `/${id2}` : ''}`, authHeader())
        .then(res => {
          if (res.data.errorCode) {
            dispatch(addNotification(res.data.errorCode, 'error'));
          } else {
            dispatch(success(namespace, res.data));
            const a = document.createElement('a'); // Create <a>
            a.href = `data:image/png;base64,${res.data.resultContainer.base64Data}`; // Image Base64 Goes here
            a.download = `${res.data.resultContainer.fileName}.${res.data.resultContainer.extension}`; // File name Here
            a.click();
          }
        })
        .then(() => {
        })
        .catch((error) => {
          dispatch(failure(namespace, error));
          if (error.response && error.response.data !== undefined) {
            if (error.response.data.errorMessage) {
              dispatch(addNotification(error.response.data.errorMessage, 'error'));
            }
          } else {
            dispatch(addNotification('500', 'error'));
          }
        });
    }
  };
}
