/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/static-property-placement */
/* eslint-disable arrow-parens */
/* eslint-disable no-shadow */
/* eslint-disable arrow-body-style */
/* eslint-disable object-shorthand */
/* eslint-disable react/prop-types */
/* eslint max-len: ["error", { "code": 400 }] */
/* eslint-disable no-unused-vars */
/* eslint-disable block-spacing */
/* eslint-disable no-lone-blocks */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable object-curly-newline */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
/* eslint-disable no-mixed-operators */
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { initialize, startSubmit } from 'redux-form';
import { Button } from 'reactstrap';
import Copyright from '../../shared/components/login/Copyright';
import { login } from '../../redux/actions/authAction';
// import bg from '../../shared/img/bg.jpg';
import bg from '../../shared/img/bg2.webp';
import logo from '../../shared/img/logo.png';
import RegisterForm from './Components/RegisterForm';
import { register, registerUpdate } from '../../redux/actions/registerAction';
import { getAddress } from '../../redux/actions/addressAction';
import { getCities } from '../../redux/actions/getCitiesAction';
import { upload } from '../../redux/actions/uploadAction';
import GenericModal from '../../shared/components/GenericModal';
import { getAction } from '../../redux/actions/getAction';

class Register extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    loginType: PropTypes.string.isRequired,
    setLoginActiveTab: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      isModalOpen: false,
      token: this.props.match.params.token,
    };
  }

  componentDidMount() {
    const { getCities, getPublicAppeal, initialValues } = this.props;
    const { token } = this.state;
    if (token) {
      getPublicAppeal(token);
    }
    getCities();
    if (initialValues && initialValues.countyDto && initialValues.countyDto.cityDto && initialValues.countyDto.cityDto.id) {
      this.onCityChange(initialValues.countyDto.cityDto.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { hospitalRegisterSuccess, uploadAuthorityDocument, hospitalRegisterToken, hospitalRegisterPublicUploadSuccess, initialValues } = this.props;
    const { isModalOpen, token } = this.state;
    if (prevProps.hospitalRegisterSuccess !== hospitalRegisterSuccess && hospitalRegisterSuccess && hospitalRegisterToken) {
      if (token) {
        uploadAuthorityDocument(this.state.selectedFile, token);
      } else
      if (this.state.selectedFile) {
        uploadAuthorityDocument(this.state.selectedFile, hospitalRegisterToken);
      }
    }
    if (prevProps.hospitalRegisterPublicUploadSuccess !== hospitalRegisterPublicUploadSuccess && hospitalRegisterPublicUploadSuccess && !isModalOpen) { // eslint-disable-line
      this.setState({ isModalOpen: true });
    }
    if (prevProps.initialValues !== initialValues && initialValues && initialValues.countyDto && initialValues.countyDto.cityDto && initialValues.countyDto.cityDto.id) {
      this.onCityChange(initialValues.countyDto.cityDto.id);
    }
  }

toggleModal = () => {
  const { initialize } = this.props;
  const { isModalOpen } = this.state;
  this.setState({ isModalOpen: !isModalOpen });
}

  onSubmit = data => {
    const { history, register, registerUpdate } = this.props;
    const { token } = this.state;
    delete data.registerTime;
    if (token) {
      registerUpdate(data, token);
    } else {
      register(data);
    }
  }

  onCityChange = (id) => {
    const { getCountiesByCity } = this.props;
    getCountiesByCity(id);
  }

  onChangePdf = (e) => {
    const { uploadImage, initialValues } = this.props;
    const { selectedFile } = this.state;
    this.setState({ selectedFile: e.target.files[0] });
  }

  handleConfirmModal = () => {
    this.setState({ isModalOpen: false });
    window.location.assign('https://aks.kepan.org.tr/welcome');
  }

  render() {
    const { cityList, countyList, hospitalRegisterSuccess, initialValues, hospitalRegisterLoading } = this.props;
    const { isModalOpen, token } = this.state;
    return (
      <>
        <div className="text-center" style={{ paddingTop: '60px', backgroundImage: `url(${bg})`, height: '100vh' }}>
          <div className="mx-auto p-5" style={{ maxWidth: '1500px', backgroundColor: '#ccccccad' }}>
            <div>{token && token}
              <h6 className="active"><span style={{ color: '#007c85' }}> KLİNİK </span> <span style={{ color: '#19456c' }}> </span> ENTERAL PARENTERAL <span style={{ color: '#007c85' }}>  NÜTRİSYON DERNEĞİ</span></h6>
              <hr sstyle="color:#56baed;" />
              <div className="fadeIn first mb-3">
                <img src={logo} style={{ width: '300px' }} alt="" />
              </div>
              {initialValues && initialValues.revisionMessage &&
              <div className="alert alert-danger" role="alert">
                {initialValues.revisionMessage}
              </div>}
              <RegisterForm onSubmit={this.onSubmit} hospitalRegisterLoading={hospitalRegisterLoading} initialValues={initialValues && initialValues.id && initialValues} cityList={cityList} onChangePdf={this.onChangePdf} countyList={countyList} onCityChange={this.onCityChange} hospitalRegisterSuccess={hospitalRegisterSuccess} />

              <GenericModal
                isModalOpen={isModalOpen}
                button={<Button className="d-none" />}
                disableBackdrop
                size="md"
                toggleModal={this.handleConfirmModal}
                content={(
                  <div>
                    Başvurunuz alınmıştır. <br />
                    Başvuru sürecinizin tamamlanması için size gelen epostadaki linke tıklamanız gerekmektedir.
                    <div>
                      <Button className="btn-custom-primary float-end mt-3" size="sm" onClick={() => this.handleConfirmModal()}>Tamam</Button>
                    </div>
                  </div>
              )}
              />
            </div>
          </div>
        </div>
        <Copyright />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    register(data) {
      delete data.document;
      const sendData = {
        ...data,
        countyId: data.countyDto && data.countyDto.id };
      delete sendData.countyDto;

      dispatch(register('hospital', 'Register_Form', sendData));
    },
    registerUpdate(data, token) {
      delete data.document;
      delete data.revisionTime;
      const sendData = {
        ...data,
        countyId: data.countyDto && data.countyDto.id };
      delete sendData.countyDto;

      dispatch(registerUpdate('hospital', 'Register_Form', sendData, token));
    },
    getCities() {
      dispatch(getCities());
    },
    getCountiesByCity(cityId) {
      dispatch(getAddress('county/bycity', cityId));
    },
    uploadAuthorityDocument(file, token) {
      dispatch(upload('hospital/register/public', file, token));
    },
    getPublicAppeal(token) {
      dispatch(getAction('hospital/register/public/bytoken', token));
    },
  };
};

const mapStateToProps = (state) => ({
  cityList: state.getCities.data,
  countyList: state.countyList.data,
  hospitalRegisterToken: state.hospitalRegister && state.hospitalRegister.res && state.hospitalRegister.res.data && state.hospitalRegister.res.data.resultContainer,
  hospitalRegisterSuccess: state.hospitalRegister.success,
  hospitalRegisterLoading: state.hospitalRegister.loading,
  hospitalRegisterPublicUploadSuccess: state.hospitalRegisterPublicUpload.success,
  initialValues: state.getHospitalRegisterWithToken.data,
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Register));
