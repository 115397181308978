/* eslint-disable react/static-property-placement */
/* eslint-disable max-len */
/* eslint max-len: ["error", { "code": 300 }] */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
/* eslint-disable arrow-parens */
/* eslint-disable no-shadow */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable  react/forbid-prop-types */
/* eslint-disable  react/no-did-update-set-state */
/* eslint-disable  react/jsx-boolean-value */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import { withTranslation } from 'react-i18next';
import renderRadioButtonField from './RadioButton';
import { renderFieldInput, renderFieldTextArea } from '../../fieldRenders';
import GenericAccordion from '../../../GenericAccordion';

class AnswerAcceptRejectRadioSection extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    label: PropTypes.string,
    submessage: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    option1: PropTypes.string,
    option2: PropTypes.string,
    option3: PropTypes.string,
    defaultChecked: PropTypes.bool,
    defaultChecked2: PropTypes.bool,
    defaultChecked3: PropTypes.bool,
    value1: PropTypes.string,
    value2: PropTypes.string,
    value3: PropTypes.string,
    description: PropTypes.string,
    disabledOption1: PropTypes.bool,
    disabledOption2: PropTypes.bool,
    disabledOption3: PropTypes.bool,
    uncontrolledTooltip1Bool: PropTypes.bool,
    uncontrolledTooltip2Bool: PropTypes.bool,
    uncontrolledTooltip3Bool: PropTypes.bool,
    uncontrolledTooltip1Text: PropTypes.string,
    uncontrolledTooltip2Text: PropTypes.string,
    uncontrolledTooltip3Text: PropTypes.string,
  };

  static defaultProps = {
    label: undefined,
    submessage: undefined,
    onChange: undefined,
    option1: undefined,
    option2: undefined,
    option3: undefined,
    defaultChecked: true,
    defaultChecked2: false,
    defaultChecked3: false,
    value1: undefined,
    value2: undefined,
    value3: undefined,
    description: undefined,
    disabledOption1: false,
    disabledOption2: false,
    disabledOption3: false,
    uncontrolledTooltip1Bool: false,
    uncontrolledTooltip2Bool: false,
    uncontrolledTooltip3Bool: false,
    uncontrolledTooltip1Text: '',
    uncontrolledTooltip2Text: '',
    uncontrolledTooltip3Text: '',
  };

  valueChange(event) {
    const { onChange, name } = this.props;
    onChange(event, name);
  }

  render() {
    const { t, label, description, submessage, submessage2, name, onChange, option1, option2, option3, defaultChecked, defaultChecked2, defaultChecked3, value1, value2, value3, disabledOption1, disabledOption2, disabledOption3,
      uncontrolledTooltip1Bool, uncontrolledTooltip2Bool, uncontrolledTooltip3Bool, uncontrolledTooltip1Text, uncontrolledTooltip2Text, showReturnMessageInput,
      uncontrolledTooltip3Text, customClassName, id, answer, answerType, docId, docExtension, docName, returnedAnswer, index, standardItemId } = this.props;

    return (
      <Row className="no-gutters pt-3 pb-2" style={{ borderBottom: '1px solid gainsboro' }}>
        <Col xs={12} md={6} xl={8} className="p-0">
          <span className="form__form-group-label">
            <b> Madde-{index}: {label}</b>
            <br />
          </span>
        </Col>
        <Col xs={12} md={6} xl={4} className="p-0">
          <div className={`d-flex ${customClassName}`} style={{ justifyContent: 'space-around' }}>
            <Field
              name={name}
              component={renderRadioButtonField}
              style={{ border: '1px solid #788d0c', padding: '0.25rem', borderRadius: '8px', marginRight: '0.5rem' }}
              label={option1 !== undefined ? option1 : 'Geri Gönder'}
              radioValue={value1 !== undefined ? value1 : 'return'}
              onChange={onChange && this.valueChange.bind(this)}
              disabled={disabledOption1}
              defaultChecked={defaultChecked}
              customLabelClass="d-grid"
            />
            <Field
              name={name}
              component={renderRadioButtonField}
              style={{ border: '1px solid #ab243e', padding: '0.25rem', borderRadius: '8px', marginRight: '0.5rem' }}
              label={option2 !== undefined ? option2 : 'Onayla'}
              radioValue={value2 !== undefined ? value2 : 'confirm'}
              onChange={onChange && this.valueChange.bind(this)}
              disabled={disabledOption2}
              defaultChecked={defaultChecked2}
              customLabelClass="d-grid"

            />
            {option3 && value3 &&
            <>
              <Field
                name={name}
                component={renderRadioButtonField}
                style={{ border: '1px solid #f0b310', padding: '0.25rem', borderRadius: '8px' }}
                label={option2 !== undefined ? option3 : 'Onayla'}
                radioValue={value2 !== undefined ? value3 : 'confirm'}
                onChange={onChange && this.valueChange.bind(this)}
                disabled={disabledOption3}
                defaultChecked={defaultChecked3}
                customLabelClass="d-grid"

              />
            </>}

          </div>
        </Col>
        {showReturnMessageInput &&
        <Col xs={12} className="mt-2 text-danger">
          Bulgularınızı yazınız
          <Field
            name={`returnMessage-${standardItemId}`}
            component={renderFieldTextArea}
            type="text"
          />
        </Col>}
        {returnedAnswer &&
          <Col xs={12} className="mt-2 text-danger p-0">
            { returnedAnswer}
          </Col>}

      </Row>
    );
  }
}

export default (withTranslation('common')(AnswerAcceptRejectRadioSection));
