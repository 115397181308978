/* eslint-disable arrow-parens */
/* eslint-disable quotes */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable object-shorthand */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
/* eslint-disable no-lonely-if */
/* eslint-disable object-curly-newline */
/* eslint-disable no-prototype-builtins */

import axios from 'axios';
import { addNotification } from './notificationActions';
import { authHeader } from '../../containers/App/helper/auth-header';
// import { userRolesControl, getUserId } from '../../containers/App/helper/auth-user-info';

function request(namespace) {
  return {
    type: `${namespace}/BY_ID_REQUEST`,
  };
}

function success(namespace, data) {
  return {
    type: `${namespace}/BY_ID_SUCCESS`,
    data,
  };
}

function failure(namespace, error) {
  return {
    type: `${namespace}/BY_ID_FAILURE`,
    error,
  };
}
function reset(namespace) {
  return {
    type: `${namespace}/BY_ID_RESET`,
  };
}


export function getById(namespace, id) {
  return dispatch => {
    if (id === "reset") {
      dispatch(reset(namespace));
    } else {
      dispatch(request(namespace));
      axios.get(`${process.env.REACT_APP_API_URL}/${namespace}/id/${id}`, authHeader())
        .then(res => {
          if (res.data.errorCode) {
            dispatch(addNotification(res.data.errorMessage, 'error'));
          } else {
            dispatch(success(namespace, res.data));
          }
        })
        .then(() => {
        })
        .catch((error) => {
          dispatch(failure(namespace, error));
          if (error.response !== undefined && error.response.data) {
            if (error.response.data.errorMessage) {
              dispatch(addNotification(error.response.data.errorMessage, 'error'));
            }
          } else {
            dispatch(addNotification('500', 'error'));
          }
        });
    }
  };
}

export function postById(namespace, id, values) {
  return dispatch => {
    dispatch(request(namespace));
    axios.post(`${process.env.REACT_APP_API_URL}/${namespace}/${id}`, values, authHeader())
      .then(res => {
        if (res.data.errorCode) {
          dispatch(addNotification(res.data.errorCode, 'error'));
        } else {
          dispatch(addNotification('save_success', 'success'));
          dispatch(success(namespace, res.data));
        }
      })
      .catch((error) => {
        dispatch(failure(namespace, error));
        if (error.response !== undefined) {
          if (error.response.data.errorMessage) {
            dispatch(addNotification(error.response.data.errorMessage, 'error'));
          }
        } else {
          dispatch(addNotification('500', 'error'));
        }
      });
  };
}
export function postByIdWithId(namespace, id, secondId, values) {
  return dispatch => {
    dispatch(request(namespace));
    axios.post(`${process.env.REACT_APP_API_URL}/${namespace}/${id}/${secondId}`, values, authHeader())
      .then(res => {
        if (res.data.errorCode) {
          dispatch(addNotification(res.data.errorCode, 'error'));
        } else {
          dispatch(addNotification('save_success', 'success'));
          dispatch(success(namespace, res.data));
        }
      })
      .catch((error) => {
        dispatch(failure(namespace, error));
        if (error.response !== undefined) {
          if (error.response.data.errorMessage) {
            dispatch(addNotification(error.response.data.errorMessage, 'error'));
          }
        } else {
          dispatch(addNotification('500', 'error'));
        }
      });
  };
}


export function putByIdWithId(namespace, id, secondId, values) {
  return dispatch => {
    dispatch(request(namespace));
    axios.put(`${process.env.REACT_APP_API_URL}/${namespace}/${id}/${secondId}`, values || {}, authHeader())
      .then(res => {
        if (res.data.errorCode) {
          dispatch(addNotification(res.data.errorCode, 'error'));
        } else {
          dispatch(addNotification('save_success', 'success'));
          dispatch(success(namespace, res.data));
        }
      })
      .catch((error) => {
        dispatch(failure(namespace, error));
        if (error.response !== undefined) {
          if (error.response.data.errorMessage) {
            dispatch(addNotification(error.response.data.errorMessage, 'error'));
          }
        } else {
          dispatch(addNotification('500', 'error'));
        }
      });
  };
}

