/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/static-property-placement */
/* eslint-disable jsx-a11y/label-has-for,guard-for-in */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/sort-comp */
/* eslint max-len: ["error", { "code": 400 }] */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable arrow-parens */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable no-class-assign */
/* eslint-disable object-shorthand */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-confusing-arrow */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable prefer-template */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-else-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable  react/self-closing-comp */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable dot-notation */
/* eslint-disable lines-between-class-members */
/* eslint-disable object-curly-spacing */
/* eslint-disable max-len */

import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col,
} from 'reactstrap';
import { connect } from 'react-redux';
import AnswerAcceptRejectSection from '../../ApplicationDetailContainer/AnswerAcceptRejectSection';

class AnswerApplicationForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      returnedAnswersArray: [],
    };
  }

  answerRejected = (e, name) => {
    if (e === 'revision') {
      if (!this.state.returnedAnswersArray.includes(name)) {
        this.setState({returnedAnswersArray: [...this.state.returnedAnswersArray, name]});
      }
    } else if (this.state.returnedAnswersArray.includes(name)) {
      this.setState({returnedAnswersArray: this.arrayRemove(this.state.returnedAnswersArray, name)});
    }
  }
  arrayRemove = (arr, value) => {
    return arr.filter((ele) => {
      return ele !== value;
    });
  }
  getAnswerByType = (item) => {
    if (item.type === 'SELECT') {
      return item.choicesDtoList.filter((el) => (el.selected === true))[0].trText;
    }
    if (item.type === 'MULTI_SELECT') {
      let selectedChoices = '';
      item.choicesDtoList.filter((el) => (el.selected === true)).forEach((el) => {
        selectedChoices += `${el.trText}, `;
      });
      return selectedChoices;
    } else {
      return item.answerDto && item.answerDto.answerText;
    }
  }
  render() {
    const {
      pristine, submitting, toggle, reset, handleSubmit, change, // redux-form props
      initialValues, formLoading, title, data, revisionAnswer, reviewAddAppealSuccess, toggleModal,
    } = this.props;
    const { returnedAnswersArray } = this.state;
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody className="p-0 p-md-2">
            {data && data.map((item, index) => (
              <>
                <AnswerAcceptRejectSection
                  label={item.text}
                  answer={this.getAnswerByType(item)}
                  answerId={item.answerDto && item.answerDto.id}
                  validate={['required']}
                  name={item.id}
                  onChange={this.answerRejected}
                  showReturnMessageInput={returnedAnswersArray.includes(item.id)}
                  answerType={item.type}
                  docId={item.docId && item.docId}
                  docExtension={item.docExtension && item.docExtension}
                  docName={item.docName && item.docName}
                  returnedAnswers={item.answerDto && item.answerDto.appealAnswerReviewDtoList}
                  revisionAnswer={revisionAnswer}
                  reviewAddAppealSuccess={reviewAddAppealSuccess}
                  initialValues={initialValues}
                />
              </>

            ))}

            {/* <ButtonToolbar style={{ justifyContent: 'space-evenly', marginTop: '20px' }}>
              <>
                <Row className="w-100 mt-2">
                  <Col xs={12} sm={6} className="mb-2 text-center">
                    <Button style={{ width: '180px' }} type="button" onClick={handleSubmit} disabled={pristine || submitting} color="success" size="sm">Kaydet</Button>
                  </Col>
                  <Col xs={12} sm={6} className="mb-2 text-center">
                    <Button style={{ width: '180px' }} onClick={() => toggle()} color="secondary" size="sm">İptal</Button>
                  </Col>
                </Row>
              </>
            </ButtonToolbar> */}
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  };
};
const mapStateToProps = (state, ownProps) => ({
});

// AnswerApplicationForm = reduxForm({
//   form: 'Answer_Application_Form', // a unique identifier for this form
//   enableReinitialize: true,
//   onSubmitFail: (errors) => scrollToFirstError(errors),
// })(AnswerApplicationForm);

export default connect(mapStateToProps, mapDispatchToProps)(AnswerApplicationForm);
