/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/extensions */
/* eslint-disable react/static-property-placement */
/* eslint-disable operator-linebreak */
/* eslint-disable react/no-did-update-set-state */
import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { userRolesControl } from '../../App/helper/auth-user-info.js';
import SidebarLink from '../sidebar/SidebarLink.jsx';
import TopbarProfile from './TopbarProfile.jsx';

class Topbar extends PureComponent {
  // static propTypes = {
  //   changeMobileSidebarVisibility: PropTypes.func.isRequired,
  //   changeSidebarVisibility: PropTypes.func.isRequired,
  // };

  render() {
    // const { changeMobileSidebarVisibility, changeSidebarVisibility } = this.props;
    return (
      <div className="topbar">
        <div className="container m-0 p-0">
          <Row className="topbar__wrapper">
            <Col md={2} className="h-100 d-none d-md-flex" style={{ flexDirection: 'column', justifyContent: 'space-around' }}>
              <div style={{ marginTop: 2, marginBottom: 2 }}>
                <NavLink
                  to={window.location.pathname === '/hospital_appeals' ? '/hospital_appeals' : '/home'}
                >
                  <div className="d-flex text-dark">
                    <div className="d-none d-lg-block" style={{ alignSelf: 'center' }}>
                      <img src="https://www.kepan.org.tr/new_templates/images/logo.png" style={{ maxwidth: '96px', width: '100%', height: 'auto' }} alt="" />
                    </div>
                    <div className="ms-2 responsive-font-size" style={{ alignSelf: 'center' }}>
                      <span style={{ color: '#007c85' }}> KLİNİK </span> <span style={{ color: '#19456c' }}> </span> ENTERAL PARENTERAL <span style={{ color: '#007c85' }}>  NÜTRİSYON DERNEĞİ </span> AKREDİTASYON KURULU
                    </div>
                  </div>
                </NavLink>
              </div>

            </Col>
            <Col xs={8} md={8} className="p-0">
              <Row className="text-center h-100 p-0">
                { userRolesControl('HOSPITAL') ? (
                  <>
                    <SidebarLink title="ANASAYFA" icon="lnr lnr-home" route="/hospital_dashboard" onClick={null} />
                    <SidebarLink title="BAŞVURULAR" icon="lnr lnr-apartment" route="/hospital_appeals" onClick={null} />
                    <SidebarLink title="NÜTRİSYON EKİBİ" icon="lnr lnr-users" route="/NST" onClick={null} />
                  </>
                )
                  : userRolesControl('COUNCIL_MEMBER') || userRolesControl('EXTERNAL_VISITOR') ?
                    <SidebarLink title="ANASAYFA" icon="lnr lnr-home" route="/user-dashboard" onClick={null} />
                    :
                    userRolesControl('ADMIN') ?
                      (
                        <>
                          <SidebarLink title="ANASAYFA" icon="lnr lnr-home" route="/home" onClick={null} />
                          <SidebarLink title="HASTANELER" icon="lnr lnr-apartment" route="/hospitals" onClick={null} />
                          <SidebarLink title="BAŞVURULAR" icon="lnr lnr-layers" route="/applications" onClick={null} />
                          <SidebarLink title="KULLANICILAR" icon="lnr lnr-user" route="/users" onClick={null} />
                        </>
                      ) : null}

              </Row>
            </Col>
            <Col xs={4} md={2} className="p-0">
              <TopbarProfile />
            </Col>
          </Row>
        </div>
      </div>

    );
  }
}

export default ((Topbar));
