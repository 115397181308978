/* eslint-disable no-unused-vars */
/* eslint-disable arrow-parens */
/* eslint-disable import/prefer-default-export */

const initialState = {
  tableData: [],
  loading: false,
  loaded: false,
  error: null,
  page: 0,
  pageSize: 20,
  selectedRows: [],
  selectedRowsData: [],
};

export const listTableReducer = (namespace) => (state = initialState, action) => {
  switch (action.type) {
    case `${namespace}/LIST_TABLE_DATA_REQUEST`: {
      return {
        ...initialState,
        tableData: state.tableData,
        page: state.page,
        pageSize: state.pageSize,
        totalCount: state.totalCount,
        loading: true,
        loaded: false,
        selectedRows: state.selectedRows,
      };
    }
    case `${namespace}/LIST_TABLE_DATA_SUCCESS`: {
      if (action.data.resultContainer.totalRowCount === 0) {
        return {
          ...state,
          tableData: [],
          page: 0,
          pageSize: 20,
          totalCount: 0,
          loading: false,
          loaded: true,
        };
      }
      return {
        ...state,
        tableData: action.data.resultContainer,
        page: action.data.pageIndex || 0,
        pageSize: state.pageSize || 20,
        totalCount: action.data.totalRowCount || -1, // to check if paginated or not in Table.jsx
        loading: false,
        loaded: true,
      };
    }
    case `${namespace}/LIST_TABLE_DATA_FAILURE`: {
      return {
        ...state,
        error: action.error,
        tableData: {
          data: [],
          page: 0,
          pageSize: state.pageSize || 20,
          totalCount: 0,
          loading: false,
        },
        loading: false,
        loaded: false,
      };
    }
    case `${namespace}/LIST_TABLE_PAGE`: {
      return {
        ...state,
        page: action.data,
        pageSize: state.pageSize,
      };
    }
    case `${namespace}/RESET_LIST_TABLE_PAGE`: {
      return {
        ...state,
        page: 0,
        pageSize: state.pageSize,
      };
    }
    case `${namespace}/LIST_TABLE_PAGE_SIZE`: {
      return {
        ...state,
        page: 0,
        pageSize: action.data,
      };
    }
    case `${namespace}/LIST_TABLE_SET_SELECTED_ROWS`: {
      return {
        ...state,
        selectedRows: action.data,
        selectedRowsData: action.selectedRowsData,
      };
    }
    case `${namespace}/LIST_TABLE_RESET_SELECTED_ROWS`: {
      return {
        ...state,
        selectedRows: [],
        selectedRowsData: [],
      };
    }
    default:
      return state;
  }
};
