/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint max-len: ["error", { "code": 300 }] */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable camelcase */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable object-curly-newline */
/* eslint-disable max-len */

import React, { PureComponent } from 'react';
import { Field, reduxForm, submit } from 'redux-form';
import {
  Button, ButtonToolbar, CardBody,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Card } from '@material-ui/core';
import { renderLoginFieldInput } from '../../shared/components/generic-form/fieldRenders';
import { rules } from '../../shared/components/generic-form/FormSections/validate';
import PasswordFormSection from '../../shared/components/generic-form/FormSections/PasswordFormSection';
import FormWithLoading from '../../shared/components/form/FormWithLoading';

class ChangePasswordForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  render() {
    const {
      handleSubmit, reset, pristine, t, submitting,
    } = this.props;
    const { showPassword } = this.state;
    return (
      <>
        <FormWithLoading className="form form--horizontal" onSubmit={handleSubmit}>
          <PasswordFormSection />
          <ButtonToolbar style={{ justifyContent: 'space-evenly', marginTop: '20px' }}>
            <Button onClick={reset} disabled={pristine || submitting}>Sıfırla</Button>
            <Button type="submit" disabled={pristine || submitting} color="success">Kaydet</Button>
          </ButtonToolbar>
        </FormWithLoading>
      </>
    );
  }
}

export default reduxForm({
  form: 'User_Change_Password_Form',
})(withRouter(ChangePasswordForm));
