/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/static-property-placement */
/* eslint-disable jsx-a11y/label-has-for,guard-for-in */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/sort-comp */
/* eslint max-len: ["error", { "code": 400 }] */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable arrow-parens */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable no-class-assign */
/* eslint-disable object-shorthand */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-confusing-arrow */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable prefer-template */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-else-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable  react/self-closing-comp */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable dot-notation */
/* eslint-disable lines-between-class-members */
/* eslint-disable object-curly-spacing */
/* eslint-disable max-len */

import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Row,
} from 'reactstrap';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import FormWithLoading from '../../../shared/components/form/FormWithLoading';
import { scrollToFirstError } from '../../../shared/components/generic-form/submitFail';
import DateTimeFormSection from '../../../shared/components/generic-form/FormSections/SingleSections/DateTimeFormSection';

class SelectAvailableDates extends PureComponent {
  componentDidMount() {
    const { change, initialValues } = this.props;
    if (initialValues.startDate) {
      change('startDate', initialValues.startDate);
    }
    if (initialValues.endDate) {
      change('endDate', initialValues.endDate);
    }
  }

  componentDidUpdate(prevProps) {
    const { initialValues, initialize, tcNo, id, info, getByTc } = this.props;
  }
  render() {
    const {
      pristine, submitting, toggle, reset, handleSubmit, change, // redux-form props
      initialValues, formLoading, title, data, setCitizenModalMode, selectCitizen, toggleModal,
    } = this.props;
    return (
      <FormWithLoading loading={formLoading} className="form form--horizontal px-2 mt-3">
        <Row className="mt-2">
          <h5 className="py-2">{title}</h5>
          <Col xs={6}>
            <DateTimeFormSection
              name="startDate"
              label="Başlangıç Tarihi"
              placeholder="Başlangıç Tarihi Seçiniz"
              disableTime={true}
            />
          </Col>
          <Col xs={6}>
            <DateTimeFormSection
              name="endDate"
              label="Bitiş Tarihi"
              placeholder="Bitiş Tarihi Seçiniz"
              disableTime={true}
            />
          </Col>
        </Row>
        {initialValues &&
        <ButtonToolbar style={{ justifyContent: 'space-evenly', marginTop: '20px' }}>
          <>
            <Row className="w-100 mt-2">
              <Col xs={12} className="mb-2 text-center">
                <Button style={{ width: '180px' }} className="btn-custom-success" type="button" onClick={handleSubmit} size="sm">Kaydet</Button>
              </Col>
            </Row>
          </>
        </ButtonToolbar>}

      </FormWithLoading>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  };
};
const mapStateToProps = (state, ownProps) => ({
});

SelectAvailableDates = reduxForm({
  form: 'Select_Available_Dates_Form', // a unique identifier for this form
  enableReinitialize: true,
  // onSubmitFail: (errors) => scrollToFirstError(errors),
})(SelectAvailableDates);

export default connect(mapStateToProps, mapDispatchToProps)(SelectAvailableDates);
