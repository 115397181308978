/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Card, CardBody, Row,
} from 'reactstrap';
import { getVisit, postVisit, putVisit } from '../../../redux/actions/visitAction';
import OnSiteAssesmentsTabsContainer from '../../Applications/Containers/OnSiteAssesmentsTabsContainer';
import AnswerVisitForm from '../components/AnswerVisitForm';

class ApplicationDetail extends PureComponent {
  componentDidMount() {
    const { getVisitData, id, mode } = this.props;
    if (id) {
      getVisitData(id);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      reviewSaveVisitSuccess, getReviewVisitData, id, reviewUpdateVisitSuccess,
    } = this.props;
    if (reviewUpdateVisitSuccess !== prevProps.reviewUpdateVisitSuccess && reviewUpdateVisitSuccess) {
      this.props.history.push({
        pathname: '/user-dashboard',
      });
    }
  }

  onSubmit = (values) => {
    const {
      initialValues, visitReviewSave, visitReviewUpdate, mode,
    } = this.props;
    delete initialValues.reviewDate;
    Object.entries(values).forEach(([key, value]) => {
      initialValues.subStandardReviewDtoList.forEach((subStandardReviewDto, i) => {
        subStandardReviewDto.evalDtoList.forEach((evalDto, j) => {
          if (key.startsWith('standardId=')) {
            if (evalDto.standardItemDto.id.toString() === key.split('=')[1].toString()) {
              initialValues.subStandardReviewDtoList[i].evalDtoList[j].status = value;
              if (value === 'SATISFIED') {
                initialValues.subStandardReviewDtoList[i].evalDtoList[j].evalText = '';
              }
            }
          } else if (key.startsWith('returnMessage-')) {
            if (evalDto.standardItemDto.id.toString() === key.split('-')[1].toString()) {
              if (initialValues.subStandardReviewDtoList[i].evalDtoList[j].status !== 'SATISFIED') {
                initialValues.subStandardReviewDtoList[i].evalDtoList[j].evalText = value;
              }
            }
          }
        });
      });
    });
    visitReviewUpdate(initialValues);
  }

  render() {
    const {
      initialValues, id, mode, reviewCreateVisit, history,
    } = this.props;
    return (
      <Card>
        <CardBody className="p-0 p-md-2">
          <Button className="mb-2 px-4" color="outline-info" size="sm" onClick={() => history.goBack()}> <i className="lnr lnr-arrow-left me-2" /> Geri</Button>
          <Row>
            {initialValues && initialValues.subStandardReviewDtoList
             && (
             <AnswerVisitForm
               initialValues={initialValues}
               mode={mode}
               onSubmit={this.onSubmit}
             />
             )}

          </Row>
        </CardBody>
      </Card>

    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getVisitData(id) {
    dispatch(getVisit('review/id', id));
  },
  visitReviewUpdate(values) {
    dispatch(putVisit('review/update', values));
  },
});
const mapStateToProps = (state, ownProps) => ({
  initialValues: state.reviewIdVisit.data,
  reviewUpdateVisitSuccess: state.reviewUpdateVisit.success,
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationDetail);
