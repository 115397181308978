import React, { PureComponent } from 'react';

class Copyright extends PureComponent {
  render() {
    return (
      <div className="w-100 text-center" style={{ position: 'fixed', bottom: '0' }}>
        <h5><b>KEPAN</b> - 2022®</h5>
      </div>
    );
  }
}

export default Copyright;
