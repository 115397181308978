/* eslint-disable react/static-property-placement */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
/* eslint-disable arrow-parens */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
/* eslint max-len: ["error", { "code": 300 }] */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-wrap-multilines */

import React, { PureComponent } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

class SelectField extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ]),
    isDisabled: PropTypes.bool,
    isFilter: PropTypes.bool,
    isEmpty: PropTypes.bool,
    isEmptyforDetailSearch: PropTypes.bool,
    isEmptyDto: PropTypes.bool,
    emptyDtoLabel: PropTypes.string,
    loading: PropTypes.bool,
    isValueZeroSelectAll: PropTypes.bool,
    disableSelected: PropTypes.bool,
    isStyledZeroOption: PropTypes.bool,
  };

  static defaultProps = {
    placeholder: '',
    options: [],
    isDisabled: false,
    isFilter: false,
    isEmpty: false,
    isEmptyforDetailSearch: false,
    isEmptyDto: false,
    emptyDtoLabel: '',
    loading: false,
    isValueZeroSelectAll: false,
    disableSelected: false,
    isStyledZeroOption: false,
  };

  handleChange = (selectedOption) => {
    const { onChange, disableSelected, onChangeGetName } = this.props;
    if (disableSelected) {
      disableSelected(selectedOption);
    }
    onChange(selectedOption.value);
    if (onChangeGetName) {
      onChangeGetName(selectedOption.label);
    }
  };

  render() {
    const {
      t, value, name, placeholder, options, isDisabled, isFilter, isEmpty, isEmptyforDetailSearch, isEmptyDto, isValueZeroSelectAll, emptyDtoLabel, loading, isStyledZeroOption, defaultValue,
      disabledOptionDescription, isRegisterField,
    } = this.props;
    const customStyles = {
      menu: styles => ({
        ...styles,
        zIndex: '1000000',
      }),
      control: styles => ({
        ...styles,
        border: '1px solid #E9E9F0',
        height: '24px',
        minHeight: '24px',
        padding: '0',
        fontSize: '13px',
        alignItems: 'flex-start',
      }),
      valueContainer: () => ({
        height: '100%',
        display: 'contents',
        width: '100%',
        padding: 0,
      }),
      indicatorsContainer: () => ({
        height: '100%',
        display: 'flex',
        padding: '2px',
        alignItems: 'center',

      }),
      menuList: () => ({
        fontSize: '13px',
      }),
      dropdownIndicator: styles => ({
        ...styles,
        padding: '0px',
        fontSize: '12px',
        color: '#A4AFB7',
        '&:hover': {
          color: '#A4AFB7',
        },

      }),
      placeholder: styles => ({
        ...styles,
        color: '#CECFD0',
      }),
      indicatorSeparator: styles => ({
        ...styles,
        marginBottom: '12px',
        marginTop: '3px',
        color: 'red',
      }),
      clearIndicator: styles => ({
        ...styles,
        padding: '0px 8px',
      }),
      input: styles => ({
        ...styles,
        height: '100%',
        display: 'flex',
        width: 'min-content',
      }),
      multiValueLabel: styles => ({
        ...styles,
        padding: '0 6px',
      }),
      option: (styles, data) => {
        return ({
          ...styles,
          minHeight: '30px',
          fontStyle: (data.value === 0 && isStyledZeroOption) ? 'italic' : 'normal',
        });
      },
    };
    const customRegisterInputStyles = {
      menu: styles => ({
        ...styles,
        zIndex: '1000000',
      }),
      control: styles => ({
        ...styles,
        border: '1px solid #E9E9F0',
        backgroundColor: '#f6f6f6',
        height: '53px',
        minHeight: '53px',
        padding: '15px 32px',
        fontSize: '12px',
        alignItems: 'flex-start',
      }),
      valueContainer: () => ({
        height: '100%',
        display: 'contents',
        width: '100%',
        padding: 0,
      }),
      indicatorsContainer: () => ({
        height: '100%',
        display: 'flex',
        padding: '2px',
        alignItems: 'center',

      }),
      menuList: () => ({
        fontSize: '13px',
        maxHeight: '350px',
        overflowY: 'scroll',
        align: 'left',
      }),
      dropdownIndicator: styles => ({
        ...styles,
        padding: '0px',
        fontSize: '12px',
        color: '#A4AFB7',
        '&:hover': {
          color: '#A4AFB7',
        },

      }),
      placeholder: styles => ({
        ...styles,
        fontSize: '13px',
        color: 'rgb(32, 36, 40)',
      }),
      indicatorSeparator: styles => ({
        ...styles,
        marginBottom: '12px',
        marginTop: '3px',
        color: 'red',
      }),
      clearIndicator: styles => ({
        ...styles,
        padding: '0px 8px',
      }),
      input: styles => ({
        ...styles,
        height: '100%',
        display: 'flex',
        width: 'min-content',
      }),
      multiValueLabel: styles => ({
        ...styles,
        padding: '0 6px',
      }),
      option: (styles, data) => {
        return ({
          ...styles,
          minHeight: '30px',
          fontStyle: (data.value === 0 && isStyledZeroOption) ? 'italic' : 'normal',
        });
      },
    };

    return (
      <Select
        name={name}
        onChange={this.handleChange}
        styles={isRegisterField ? customRegisterInputStyles : customStyles}
        defaultValue={defaultValue}
        options={options}
        className={name.split('.')[0]}
        placeholder={placeholder}
        isDisabled={loading || isDisabled} // disable while loading
        noOptionsMessage={(input) => {
          return (
            <>
              {(input && input.inputValue) ?
                <>
                  <span style={{ fontStyle: 'italic' }}>{`"${input.inputValue}"`}</span>
                  sonuç bulunamadı
                </>
                :
                <>
                  sonuç yok
                </>}
            </>
          );
        }}
        isLoading={loading}
        // formatOptionLabel={(option, { context }) => {
        //   /* context can be either `menu` or `value`
        //      menu - dropdown
        //      value - value displayed
        //   */
        //   return context !== 'menu' && option.chipLabel ? option.chipLabel : option.label;
        // }}
        classNamePrefix="react-select"
        isOptionDisabled={option => option.isDisabled}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary: '#05A6A6',
            primary25: '#05A6A64C',
          },
        })}
      />
    );
  }
}

const renderSelectField = (props) => {
  const {
    t, input, meta, options, placeholder, isDisabled, isFilter, isEmpty, isEmptyforDetailSearch, isEmptyDto, isValueZeroSelectAll, emptyDtoLabel, loading, disableSelected, isStyledZeroOption, defaultValue,
    disabledOptionDescription, isRegisterField,
  } = props;
  return (
    <div className="form__form-group-input-wrap">
      {isRegisterField && meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
      <SelectField
        {...input}
        t={t}
        options={options}
        placeholder={placeholder}
        isDisabled={isDisabled}
        isFilter={isFilter}
        isEmpty={isEmpty}
        isEmptyforDetailSearch={isEmptyforDetailSearch}
        isEmptyDto={isEmptyDto}
        isValueZeroSelectAll={isValueZeroSelectAll}
        emptyDtoLabel={emptyDtoLabel}
        loading={loading}
        disableSelected={disableSelected}
        isStyledZeroOption={isStyledZeroOption}
        defaultValue={defaultValue}
        disabledOptionDescription={disabledOptionDescription}
        isRegisterField={isRegisterField}
      />
      {!isRegisterField && meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}

    </div>
  );
};

renderSelectField.propTypes = {
  t: PropTypes.func.isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ]),
  isFilter: PropTypes.bool,
  isEmpty: PropTypes.bool,
  isEmptyforDetailSearch: PropTypes.bool,
  isEmptyDto: PropTypes.bool,
  emptyDtoLabel: PropTypes.string,
  loading: PropTypes.bool,
  isValueZeroSelectAll: PropTypes.bool,
  disableSelected: PropTypes.bool,
  isStyledZeroOption: PropTypes.bool,
};

renderSelectField.defaultProps = {
  meta: null,
  options: [],
  placeholder: '',
  isDisabled: false,
  isFilter: false,
  isEmpty: false,
  isEmptyforDetailSearch: false,
  isEmptyDto: false,
  emptyDtoLabel: '',
  loading: false,
  isValueZeroSelectAll: false,
  disableSelected: false,
  isStyledZeroOption: false,
};

export default renderSelectField;
