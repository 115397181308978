/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/static-property-placement */
/* eslint max-len: ["error", { "code": 300 }] */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
/* eslint-disable arrow-parens */
/* eslint-disable no-shadow */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable  react/forbid-prop-types */
/* eslint-disable  react/no-did-update-set-state */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, change, formValueSelector, getFormValues, FormSection } from 'redux-form';
import { connect } from 'react-redux';
// import { fetchCountry } from '../../../../redux/actions/countryUniDeptActions';
// import { fetchCityList } from '../../../../redux/actions/cityListAction';
import { Col, Row } from 'reactstrap';
import InputSection from './SingleSections/InputSection';
import SelectSection from './SingleSections/SelectSection';
import { list } from '../../../../redux/actions/listAction';
import { getAddress } from '../../../../redux/actions/addressAction';

class AdressFormSection extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    mode: PropTypes.oneOf(['create', 'edit', 'view']).isRequired,
    initialValues: PropTypes.any,
    label: PropTypes.string.isRequired,
    validate: PropTypes.object,
    streetBool: PropTypes.bool,
    countryBool: PropTypes.bool,
    cityBool: PropTypes.bool,
    countyBool: PropTypes.bool,
    zipCodeBool: PropTypes.bool,
    careerFairCountry: PropTypes.bool,
    fetchCountry: PropTypes.func.isRequired,
    fetchCityList: PropTypes.func.isRequired,
    submessage: PropTypes.string,
    maxLength: PropTypes.number,
    character: PropTypes.string,
    streetLabelBool: PropTypes.bool,
    // streetDto: PropTypes.bool,
  };

  static defaultProps = {
    initialValues: undefined,
    validate: {
      street: [],
      country: [],
      city: [],
      county: [],
      zipcode: [],

    },
    streetBool: false,
    countryBool: false,
    cityBool: false,
    countyBool: false,
    zipCodeBool: false,
    careerFairCountry: false,
    submessage: undefined,
    maxLength: undefined,
    character: undefined,
    streetLabelBool: true,
    // streetDto: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      cityDisabled: true,
    };
  }

  componentDidMount() {
    const { fetchCities, fetchCountiesByCity } = this.props;
    fetchCities();
    // fetchCountiesByCity(1);
  }

  componentDidUpdate(prevProps) {
    const { initialValues, fetchCountiesByCity, fetchDistrictsByCounty, name, fetchStreetsByDistrict, formValues, change, form, cities, mode } = this.props;
    const currentValues = formValues && formValues[name];
    if ((cities && cities.length > 0) && (!currentValues || (currentValues && !currentValues.cityDto))) {
      change(form, `${name}.cityDto.id`, cities[0].id);
    }
    if ((prevProps.initialValues !== initialValues && initialValues) || (prevProps.formValues !== formValues && formValues)) {
      if (currentValues) {
        if (currentValues.cityDto) {
          fetchCountiesByCity(currentValues.cityDto.id);
        }
        if (currentValues.countyDto) {
          fetchDistrictsByCounty(currentValues.countyDto.id);
        }
        if (currentValues.districtDto) {
          fetchStreetsByDistrict(currentValues.districtDto.id);
        }
      }
    }
  }

  cityChange = (id) => {
    const { fetchCountiesByCity } = this.props;
    fetchCountiesByCity(id);
  }

  countyChange = (id) => {
    const { fetchDistrictsByCounty } = this.props;
    fetchDistrictsByCounty(id);
  }

  districtChange = (id, label) => {
    const { fetchStreetsByDistrict } = this.props;
    fetchStreetsByDistrict(id);
  }

  render() {
    const {
      t, mode, character, initialValues, maxLength, validate, streetDto, city,
      streetBool, countryBool, cityBool, countyBool, zipCodeBool,
      careerFairCountry, label, submessage, name, isGrid,
      countryLoading, cityLoading, noLabel, formValues,
      cities, counties, streets, districts, isTripleGrid,
    } = this.props;
    const { languages, cityDisabled } = this.state;
    const capitalize = value => value && value.toUpperCase('tr-TR');
    const noLabelStyle = (noLabel && mode === 'view') ? { width: '100%', display: 'flex', margin: '0', padding: '0' } : {};
    const currentValues = formValues && formValues[name];
    return (
      <FormSection name={name}>
        <div className="form__form-group">
          <h6 style={{ textDecoration: 'underline' }}>{label || 'Adres' } </h6>
          <Row>
            <Col xs={isGrid ? 6 : isTripleGrid ? 4 : 12}>
              <SelectSection
                label="Şehir"
                name="cityDto.id"
                disabled
            // loading={}
            // placeholder="Ankara"
                options={cities && cities.map((el) => ({ value: el.id, label: el.name }))}
                onChange={this.cityChange}
                mode={mode}
                initialValues={initialValues && initialValues[name]}
              />
            </Col>
            <Col xs={isGrid ? 6 : isTripleGrid ? 4 : 12}>
              <SelectSection
                label="İlçe"
                name="countyDto.id"
                options={counties && counties.map((el) => ({ value: el.id, label: el.name }))}
                onChange={this.countyChange}
                mode={mode}
                initialValues={initialValues && initialValues[name]}
              />
            </Col>
            <Col xs={isGrid ? 6 : isTripleGrid ? 4 : 12}>
              <SelectSection
                label="Mahalle"
                name="districtDto.id"
                options={districts && districts.map((el) => ({ value: el.id, label: el.name }))}
                disabled={!(currentValues && currentValues.countyDto)}
                mode={mode}
                onChange={this.districtChange}
                initialValues={initialValues && initialValues[name]}
              />
            </Col>
            <Col xs={isGrid ? 6 : isTripleGrid ? 4 : 12}>
              <SelectSection
                label="Sokak/Cadde"
                name="streetDto.id"
                mode={mode}
                initialValues={initialValues && initialValues[name]}
                options={streets && streets.map((el) => ({ value: el.id, label: el.name }))}
                disabled={!(currentValues && currentValues.districtDto)}
              />
            </Col>
            {/* } */}
            <Col xs={isGrid ? 6 : isTripleGrid ? 4 : 12}>
              <InputSection
                label="Bina No"
                name="buildingNo"
                type="text"
                mode={mode}
                initialValues={initialValues && initialValues[name]}
              />
            </Col>
            <Col xs={isGrid ? 6 : isTripleGrid ? 4 : 12}>
              <InputSection
                label="Apartman/Site Adı"
                name="siteName"
                type="text"
                mode={mode}
                initialValues={initialValues && initialValues[name]}
              />
            </Col>
            <Col xs={isGrid ? 6 : isTripleGrid ? 4 : 12}>
              <InputSection
                label="Blok"
                name="blockName"
                type="text"
                mode={mode}
                initialValues={initialValues && initialValues[name]}
              />
            </Col>
            <Col xs={isGrid ? 6 : isTripleGrid ? 4 : 12}>
              <InputSection
                label="Daire No"
                name="apartmentNo"
            // validate={['required']}
                type="text"
                mode={mode}
                initialValues={initialValues && initialValues[name]}
              />
            </Col>
          </Row>

        </div>
      </FormSection>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    change(form, field, value) {
      dispatch(change(form, field, value));
    },
    fetchCities() {
      dispatch(list('city'));
    },
    fetchCountiesByCity(id) {
      dispatch(getAddress('county/bycity', id));
    },
    fetchDistrictsByCounty(id) {
      dispatch(getAddress('district/bycounty', id));
    },
    fetchStreetsByDistrict(id) {
      dispatch(getAddress('street/bydistrict', id));
    },
  };
};
const mapStateToProps = (state, ownProps) => ({
  formValues: getFormValues(ownProps.form)(state),
  cities: state.cityList.data,
  counties: state.countyList.data,
  districts: state.districtList.data,
  streets: state.streetList.data,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdressFormSection);
