/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import {
  Modal, ModalBody,
} from 'reactstrap';

class GenericModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // isModalOpen: false,
    };
  }

  render() {
    const {
      toggleModal, content, // redux-form props
      isModalOpen, button, modalTitle, disabled, onClosed, size, disableBackdrop,
    } = this.props;
    return (
      <>
        {React.cloneElement(button, { onClick: !disabled ? toggleModal : disabled })}
        <Modal
          toggle={toggleModal}
          isOpen={isModalOpen}
          centered
          backdrop={!disableBackdrop}
          className={size ? `modal-${size}` : 'modal-xl'}
          onClosed={onClosed}
        >
          <ModalBody style={{ padding: '2rem' }}>
            <button
              onClick={toggleModal}
              type="button"
              className="modal__close-btn"
              variant="text"
            ><i className="far fa-times-circle" />
            </button>
            {modalTitle && <h6 style={{ textAlign: 'center' }}>{modalTitle}</h6>}
            {content}
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default GenericModal;
