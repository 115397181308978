/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/static-property-placement */
/* eslint-disable arrow-parens */
/* eslint-disable no-shadow */
/* eslint-disable arrow-body-style */
/* eslint-disable object-shorthand */
/* eslint-disable react/prop-types */
/* eslint max-len: ["error", { "code": 400 }] */
/* eslint-disable no-unused-vars */
/* eslint-disable block-spacing */
/* eslint-disable no-lone-blocks */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable object-curly-newline */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
/* eslint-disable no-mixed-operators */
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { startSubmit } from 'redux-form';
import { Button } from 'reactstrap';
import Copyright from '../../shared/components/login/Copyright';
import { login } from '../../redux/actions/authAction';
import LoginForm from './components/LoginForm';
// import bg from '../../shared/img/bg.jpg';
import bg from '../../shared/img/bg2.webp';

import logo from '../../shared/img/logo.png';

class Login extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    loginType: PropTypes.string.isRequired,
    setLoginActiveTab: PropTypes.func,
  };

  joinPathName = (stringArray) => {
    const out = [];
    for (let i = 2; i < stringArray.length; i++) {
      out.push(stringArray[i]);
    }
    return out.join('/');
  }

  onSubmit = data => {
    const {
      login, setSubmittingState, history, location,
    } = this.props;
    // setSubmittingState('Login_Form', true);
    const pathName = queryString.parse(location.search);
    login(history, pathName, data);
  }

  render() {
    const { history } = this.props;
    return (
      <>
        <div className="text-center" style={{ paddingTop: '60px', backgroundImage: `url(${bg})`, height: '100vh' }}>
          <div className="mx-auto p-5" style={{ maxWidth: '500px', backgroundColor: '#ccccccad' }}>
            <div>
              <h6 className="active"><span style={{ color: '#007c85' }}> KLİNİK </span> <span style={{ color: '#19456c' }}> </span> ENTERAL PARENTERAL <span style={{ color: '#007c85' }}>  NÜTRİSYON DERNEĞİ</span></h6>
              <hr sstyle="color:#56baed;" />
              <div className="fadeIn first mb-3">
                <img src={logo} style={{ width: '300px' }} alt="" />
              </div>
              <LoginForm onSubmit={this.onSubmit} />
              <div className="mt-4" style={{ textAlign: 'center' }}>
                <h6>Üye değil misiniz?</h6>
                <Button onClick={() => history.push('/register')} className="underlineHover ms-auto px-4" color="outline-dark" size="sm" type="button">Hesap Oluştur</Button>
              </div>

            </div>
          </div>
        </div>
        <Copyright />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login(history, fromPath, values) {
      dispatch(login(history, fromPath, values));
    },
    // setSubmittingState: (form, isSubmitting) => {
    //   if (isSubmitting === true) {
    //     dispatch(startSubmit(form));
    //   }
    // },
  };
};
export default connect(null, mapDispatchToProps)(withRouter(Login));
