/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/static-property-placement */
/* eslint-disable jsx-a11y/label-has-for,guard-for-in */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/sort-comp */
/* eslint max-len: ["error", { "code": 400 }] */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable arrow-parens */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable no-class-assign */
/* eslint-disable object-shorthand */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-confusing-arrow */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable prefer-template */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-else-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable  react/self-closing-comp */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable dot-notation */
/* eslint-disable lines-between-class-members */
/* eslint-disable object-curly-spacing */
/* eslint-disable max-len */

import React, { PureComponent } from 'react';
import {
  Col, ButtonToolbar, Button, Row,
} from 'reactstrap';
import { formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import FormWithLoading from '../../../shared/components/form/FormWithLoading';
import { scrollToFirstError } from '../../../shared/components/generic-form/submitFail';
import { list } from '../../../redux/actions/listAction';
import TextAreaSection from '../../../shared/components/generic-form/FormSections/SingleSections/TextAreaSection';
import InputSection from '../../../shared/components/generic-form/FormSections/SingleSections/InputSection';

class ExtendDateForm extends PureComponent {
  render() {
    const {
      pristine, submitting, toggle, reset, handleSubmit, change, // redux-form props
      initialValues, formLoading, title, data, cancelButtonOnclick, selectCitizen, toggleModal,
    } = this.props;
    return (
      <Col md={12} lg={12}>
        <FormWithLoading className="form form--horizontal px-2">
          <Row>
            <h5>Eklemek istediğiniz gün sayısını giriniz.</h5>
            <InputSection
              label="Gün Sayısı"
              name="days"
              type="number"
              style={{ padding: '1rem' }}
              validate={['required', 'max_180']}
            />
          </Row>

          <ButtonToolbar style={{ justifyContent: 'space-evenly', marginTop: '20px' }}>
            <>
              <Row className="w-100 mt-2">
                <Col xs={12} sm={6} className="mb-2 text-center">
                  <Button style={{ width: '180px' }} type="button" onClick={handleSubmit} disabled={pristine || submitting} color="success" size="sm">Kaydet</Button>
                </Col>
                <Col xs={12} sm={6} className="mb-2 text-center">
                  <Button style={{ width: '180px' }} onClick={() => cancelButtonOnclick()} color="secondary" size="sm">İptal</Button>
                </Col>
              </Row>
            </>
          </ButtonToolbar>
        </FormWithLoading>
      </Col>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {

  };
};
const mapStateToProps = (state, ownProps) => ({
});

ExtendDateForm = reduxForm({
  form: 'Extend_Date_Form', // a unique identifier for this form
  enableReinitialize: true,
})(ExtendDateForm);

export default connect(mapStateToProps, mapDispatchToProps)(ExtendDateForm);
