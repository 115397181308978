/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/static-property-placement */
/* eslint-disable jsx-a11y/label-has-for,guard-for-in */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/sort-comp */
/* eslint max-len: ["error", { "code": 400 }] */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable arrow-parens */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable no-class-assign */
/* eslint-disable object-shorthand */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-confusing-arrow */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable prefer-template */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-else-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable  react/self-closing-comp */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable dot-notation */
/* eslint-disable lines-between-class-members */
/* eslint-disable object-curly-spacing */
/* eslint-disable max-len */

import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Row,
} from 'reactstrap';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
// import Alert from '../../../../shared/components/Alert';
// import { info, viewInfo } from '../../../../redux/actions/form/infoAction';
import FormWithLoading from '../../../shared/components/form/FormWithLoading';
import InputSection from '../../../shared/components/generic-form/FormSections/SingleSections/InputSection';
import SelectSection from '../../../shared/components/generic-form/FormSections/SingleSections/SelectSection';
import MaskedInputSection from '../../../shared/components/generic-form/FormSections/SingleSections/MaskedInputSection';
import AddressFormSection from '../../../shared/components/generic-form/FormSections/AddressFormSection';
import { info } from '../../../redux/actions/infoAction';
import { getById } from '../../../redux/actions/byIdAction';
import { scrollToFirstError } from '../../../shared/components/generic-form/submitFail';
import DateTimeFormSection from '../../../shared/components/generic-form/FormSections/SingleSections/DateTimeFormSection';
import TextAreaSection from '../../../shared/components/generic-form/FormSections/SingleSections/TextAreaSection';
import PdfUploadFormSection from '../../../shared/components/generic-form/FormSections/PdfUploadFormSection';
import GenericModal from '../../../shared/components/GenericModal';
import { getAction } from '../../../redux/actions/getAction';
import { postAction } from '../../../redux/actions/postAction';
import { putAction } from '../../../redux/actions/putAction';

class AddTeamMemberForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isDeleteModalOpen: false,
    };
  }

  componentDidMount() {
    const { getMember, id } = this.props;
    if (id) {
      getMember(id);
    } else {
      getMember('reset');
    }
  }

  componentDidUpdate(prevProps) {
    const { nutritionMemberDeleteSuccess } = this.props;
    if (prevProps.nutritionMemberDeleteSuccess !== nutritionMemberDeleteSuccess && nutritionMemberDeleteSuccess) {
      this.setState({ isDeleteModalOpen: false });
    }
  }
  onChangePdf = (e) => {
    const { uploadImage, initialValues } = this.props;
    if (e.target.files[0]) {
      uploadImage(e.target.files[0], initialValues.id);
    }
  }

  toggleDeleteModal = () => { this.setState((prevState) => ({ isDeleteModalOpen: !prevState.isDeleteModalOpen })); }

  deleteMemeber = (id) => {
    const { deleteMember } = this.props;
    deleteMember(id);
  }
  render() {
    const { pristine, submitting, toggle, handleSubmit, initialValues, formLoading, mode } = this.props;
    const { isDeleteModalOpen } = this.state;

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody className="p-0 p-md-2">
            <h6>Nütrisyon Destek Ekibi Üyesi Ekle/Düzenle</h6>
            <FormWithLoading loading={formLoading} className="form form--horizontal">
              <Row>
                <Col xs={6}>
                  <InputSection
                    label="Adı"
                    name="name"
                    type="text"
                    mode={mode}
                    initialValues={initialValues}
                    validate={['required']}
                  />
                </Col>
                <Col xs={6}>
                  <InputSection
                    label="Soyadı"
                    name="surname"
                    type="text"
                    mode={mode}
                    initialValues={initialValues}
                    validate={['required']}
                  />
                </Col>
                <Col xs={6}>
                  <InputSection
                    label="Ünvanı"
                    name="title"
                    type="text"
                    mode={mode}
                    initialValues={initialValues}
                  />
                </Col>
                <Col xs={6}>
                  <InputSection
                    label="Eposta"
                    name="email"
                    validate={['required']}
                    type="email"
                    initialValues={initialValues}
                    mode={mode}
                  />
                </Col>
                <Col xs={6}>
                  <SelectSection
                    label="NDB'de Görevi"
                    mode={mode}
                    initialValues={initialValues}
                    name="memberType"
                    validate={['required']}
                    options={[
                      { value: 'DOCTOR', label: 'HEKİM' },
                      { value: 'NURSE', label: 'HEMŞİRE' },
                      { value: 'PHARMACIST', label: 'ECZACI' },
                      { value: 'NUTRISIONIST', label: 'DİYETİSYEN' },
                      { value: 'OTHER', label: 'DİĞER' },
                    ]}
                  />
                </Col>
                <Col xs={6} className="mb-2">
                  <DateTimeFormSection
                    name="startDate"
                    label="NDB'de Göreve Başlama Tarihi"
                    placeholder="Başlangıç Tarihi"
                    disableTime={true}
                    validate={['required']}
                  />
                </Col>
                <Col xs={6}>
                  <InputSection
                    label="Bitirdiği Üniversite"
                    validate={['required']}
                    name="graduatedUniversity"
                    type="text"
                    mode={mode}
                    initialValues={initialValues}
                  />
                </Col>
                <Col xs={6}>
                  {/* {initialValues?.document ?
                    <><Button>Belgeyi Görüntüle</Button> - <Button>Belgeyi Değiştir</Button></>
                    : <PdfUploadFormSection
                        name="document"
                        label="Ne Belgesi?"
                        onChange={(e) => this.onChangePdf(e)}
                        validate={['required']}
                        initialValues={initialValues}
                    />} */}

                </Col>
                <Col xs={6}>
                  <TextAreaSection
                    name="careerHistory"
                    validate={['required']}
                    label="Varsa Öncesinde Çalıştığı Kurumlar Ve Görevleri"
                    maxLength={250}
                  />
                </Col>
                <Col xs={6}>
                  <TextAreaSection
                    name="educationMeeting"
                    label="Katıldığı Eğitim Toplantıları"
                    maxLength={250}
                  />
                </Col>
              </Row>

              <ButtonToolbar style={{ justifyContent: 'space-evenly', marginTop: '20px' }}>
                <>
                  <Row className="w-100 mt-2">
                    <Col xs={12} sm={6} className="mb-2 text-center">
                      <Button style={{ width: '180px' }} type="button" onClick={handleSubmit} disabled={pristine || submitting} color="success" size="sm">Kaydet</Button>
                    </Col>
                    <Col xs={12} sm={6} className="mb-2 text-center">
                      <Button style={{ width: '180px' }} onClick={() => toggle()} color="secondary" size="sm">İptal</Button>
                    </Col>
                    {initialValues && initialValues.id &&
                    <Col className="text-danger" style={{ textAlign: 'right' }}>
                      <GenericModal
                        isModalOpen={isDeleteModalOpen}
                        size="sm"
                        button={(
                          <span onClick={() => this.deleteMemeber(initialValues.id)} style={{cursor: 'pointer', textDecoration: 'underline'}}>Üyeyi Sil</span>
                            )}
                        toggleModal={this.toggleDeleteModal}
                        content={
                          <div>
                            <div className="mb-5">
                              <h5>Üyeyi istediğinize emin misiniz?</h5>
                            </div>
                            <ButtonToolbar style={{ justifyContent: 'space-around' }}>
                              <Button style={{ width: '80px' }} onClick={() => this.toggleDeleteModal()} color="secondary">Vazgeç</Button>
                              <Button style={{ width: '80px' }} type="button" onClick={() => this.deleteMemeber(initialValues.id)} color="danger">Sil</Button>
                            </ButtonToolbar>

                          </div>
                            }
                      />

                    </Col>}
                  </Row>
                </>
              </ButtonToolbar>
            </FormWithLoading>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMember(id) {
      dispatch(getById('hospital/nutrition/member', id));
    },
    uploadImage(file, id) {
      dispatch(getById('hospital/nutrition/member', file, id));
    },
    deleteMember(id) {
      dispatch(putAction('hospital/nutrition/member/delete', {}, id));
    },
  };
};
const mapStateToProps = (state, ownProps) => ({
  initialValues: state.hospitalNutritionById.data,
  nutritionMemberDeleteSuccess: state.nutritionMemberDelete.success,
});

AddTeamMemberForm = reduxForm({
  form: 'Add_Team_Member_Form', // a unique identifier for this form
  enableReinitialize: true,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(AddTeamMemberForm);

export default connect(mapStateToProps, mapDispatchToProps)(AddTeamMemberForm);
