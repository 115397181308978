/* eslint-disable max-len */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */

import { animateScroll } from 'react-scroll';

const flatten = (arr) => {
  return arr.reduce((flat, toFlatten) => flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten), []);
};

const getErrorFieldNames = (obj, name = '') => {
  const errorArr = [];
  errorArr.push(Object.keys(obj).map((key) => {
    const next = obj[key];
    if (next) {
      if (typeof next === 'string') {
        return name + key;
      } else if (typeof next === 'object') {
        return key;
      }
      // Keep looking
      if (next.map) {
        errorArr.push(next.map((item, index) => getErrorFieldNames(item, `${name}${key}[${index}].`)).filter((o) => o));
      }
    }
    return null;
  }).filter((o) => o));
  return flatten(errorArr);
};

export const scrollToFirstError = (errors) => {
  const errorFields = getErrorFieldNames(errors);

  for (let i = 0; i < errorFields.length; i++) {
    const fieldName = `${errorFields[i]}`;
    const elements = document.querySelectorAll(`[class="${fieldName}"], [name="${fieldName}"], .${fieldName}`);
    if (elements.length) {
      animateScroll.scrollTo(elements[0].getBoundingClientRect().top + window.scrollY - 100); // animate directly to the right position
      break;
    }
  }
};
