/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
/* eslint max-len: ["error", { "code": 300 }] */
/* eslint-disable no-console */
/* eslint-disable arrow-parens */
/* eslint-disable quotes */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable one-var */
/* eslint-disable one-var-declaration-per-line */
/* eslint-disable no-lonely-if */
import axios from 'axios';
import { authFileHeader, authHeader } from '../../containers/App/helper/auth-header';
// import store from '../../../containers/App/store';
// import { authHeader } from '../../../containers/App/helper/auth-header';
import { addNotification } from './notificationActions';

function request(namespace, id) {
  return {
    type: `${namespace}/UPLOAD_REQUEST`,
    id,
  };
}

function success(namespace, data) {
  return {
    type: `${namespace}/UPLOAD_SUCCESS`,
    data,
  };
}

function failure(namespace, error) {
  return {
    type: `${namespace}/UPLOAD_FAILURE`,
    error,
  };
}

export function upload(namespace, file, id) {
  const formData = new FormData();
  formData.append('file', file);

  return dispatch => {
    dispatch(request(namespace));
    axios.post(`${process.env.REACT_APP_API_URL}/${namespace}/upload${id ? `/${id}` : ''}`, formData, authFileHeader())
      .then(res => {
        if (res.data.errorCode) {
          if (res.data.errorMessage) {
            dispatch(addNotification(res.data.errorMessage, 'error'));
          } else {
            dispatch(addNotification(res.data.errorCode, 'error'));
          }
        } else {
          dispatch(success(namespace, res));
          dispatch(addNotification('save_success', 'success'));
        }
      })
      .catch(error => {
        dispatch(failure(error));
        if (error.response && error.response.data !== undefined) {
          if (error.response.data.errorCode) {
            if (error.response.data.errorMessage) {
              dispatch(addNotification(error.response.data.errorMessage, 'error'));
            } else {
              dispatch(addNotification(error.response.data.errorCode, 'error'));
            }
          } else {
            dispatch(addNotification(error.response.status, 'error'));
          }
        } else {
          dispatch(addNotification('unknown_error', 'error'));
        }
      });
  };
}
export function uploadWithFileName(namespace, values, id) {
  const formData = new FormData();
  formData.append('file', values.file[0]);
  formData.append('title', values.title);

  return dispatch => {
    dispatch(request(namespace));
    axios.post(`${process.env.REACT_APP_API_URL}/${namespace}/upload${id ? `/${id}` : ''}`, formData, authFileHeader())
      .then(res => {
        if (res.data.errorCode) {
          if (res.data.errorMessage) {
            dispatch(addNotification(res.data.errorMessage, 'error'));
          } else {
            dispatch(addNotification(res.data.errorCode, 'error'));
          }
        } else {
          dispatch(success(namespace, res));
          dispatch(addNotification('save_success', 'success'));
        }
      })
      .catch(error => {
        dispatch(failure(error));
        if (error.response && error.response.data !== undefined) {
          if (error.response.data.errorCode) {
            if (error.response.data.errorMessage) {
              dispatch(addNotification(error.response.data.errorMessage, 'error'));
            } else {
              dispatch(addNotification(error.response.data.errorCode, 'error'));
            }
          } else {
            dispatch(addNotification(error.response.status, 'error'));
          }
        } else {
          dispatch(addNotification('unknown_error', 'error'));
        }
      });
  };
}

export function uploadWithPromise(namespace, file, id) {
  const promiseArray = [];
  const propertyValues = Object.values(file);
  propertyValues.forEach((item, index) => {
    const formData = new FormData();
    formData.append('image', item);
    const promise = new Promise((resolve, reject) => {
      resolve(axios.post(`${process.env.REACT_APP_API_URL}/${namespace}/upload/${id}`, formData, authFileHeader())).then(res => res).catch(error => reject(new Error(error)));
    });
    promiseArray.push(
      promise,
    );
  });
  return dispatch => {
    dispatch(request(namespace, id));
    Promise.allSettled(promiseArray).then(results => {
      results.forEach((item, index) => {
        if (item.status === "fulfilled") {
          dispatch(success(namespace, item.value.data));
        } else {
          dispatch(failure(item.reason));
        }
      });
      dispatch(addNotification(`${results.filter(result => result.status === 'fulfilled').length} İşlem Başarılı, ${results.filter(result => result.status === 'rejected').length} İşlem Başarısız`, 'success'));
    });
    // .catch(error => {
    //   dispatch(failure(namespace, error));
    //   if (error.response && error.response.data !== undefined) {
    //     if (error.response.data.errorCode) {
    //       if (error.response.data.errorMessage) {
    //         dispatch(addNotification(error.response.data.errorMessage, 'error'));
    //       } else {
    //         dispatch(addNotification(error.response.data.errorCode, 'error'));
    //       }
    //     } else {
    //       dispatch(addNotification(error.response.status, 'error'));
    //     }
    //   } else {
    //     dispatch(addNotification('unknown_error', 'error'));
    //   }
    // });
  };
}
