/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { appealStatus } from '../App/helper/statusTableLookupValues';

const ScoreStandardsDetail = (props) => (

  <div>
    {props.initialValues
    && (
    <Alert className="mb-3" severity={props.initialValues?.status === 'REJECTED' ? 'error' : props.initialValues?.status === 'MEMBER_CONFIRMATION' ? 'success' : 'warning'}>
      <AlertTitle>Başvuru Puanlama Detayları</AlertTitle>
      <AlertTitle>Durum: {appealStatus[props.initialValues?.status]}</AlertTitle>
      <ul className="mb-0" style={{ listStyle: 'none', fontSize: '15px' }}>
        {props?.initialValues?.subStandardResultDtoList.map((subStandard) => (
          <li className={subStandard.evalStatus === 'SATISFIED'
            ? 'text-success'
            : 'text-danger'}
          >
            <span className="me-2" style={{ fontSize: '18px' }}>
              {subStandard.evalStatus === 'SATISFIED'
                ? <i className="lnr lnr-checkmark-circle text-success" />
                : <i className="lnr lnr-cross-circle text-danger" />}
            </span>
            {/* %{(subStandard?.reviewScore / subStandard?.maxScore).toFixed(2) * 100} - {subStandard?.subStandardDto?.title} */}
            {subStandard?.avgScore} - {subStandard?.subStandardDto?.title}
          </li>
        ))}
      </ul>
    </Alert>
    )}

  </div>
);

export default ScoreStandardsDetail;
