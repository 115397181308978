/* eslint-disable react/prop-types */
/* eslint-disable max-len */

import { Chip } from '@mui/material';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

class RenderStatusChip extends PureComponent {
  componentDidMount() {
  }

  render() {
    const {
      status, statusLookup,
    } = this.props;
    const element = statusLookup[status];

    if (element) {
      return (
        React.cloneElement(
          element,
          { label: element.props.label },
        )
      );
    }
    return (
      <Chip className="bg-secondary" style={{ color: 'white' }} size="small" label={(element && element.props.label)} />
    );
  }
}

const mapDispatchToProps = () => ({
});
const mapStateToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(RenderStatusChip);
