/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/static-property-placement */
/* eslint-disable jsx-a11y/label-has-for,guard-for-in */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/sort-comp */
/* eslint max-len: ["error", { "code": 400 }] */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable arrow-parens */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable no-class-assign */
/* eslint-disable object-shorthand */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-confusing-arrow */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable prefer-template */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-else-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable  react/self-closing-comp */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable dot-notation */
/* eslint-disable lines-between-class-members */
/* eslint-disable object-curly-spacing */
/* eslint-disable max-len */

import React, { PureComponent } from 'react';
import {
  Card, CardBody,
} from 'reactstrap';
import { connect } from 'react-redux';
import ExecutiveInfoForm from './ExecutiveInfoForm';
import { putAction } from '../../../redux/actions/putAction';
import { getAction } from '../../../redux/actions/getAction';

class index extends PureComponent {
  componentDidMount() {
    this.props.getAuthority();
  }
  componentDidUpdate(prevProps) {
    const { hospitalAuthorityChange, initialValues } = this.props;
    if (prevProps.hospitalAuthorityChange !== hospitalAuthorityChange && hospitalAuthorityChange) {
      this.props.getAuthority();
    }
    if (prevProps.initialValues.email !== initialValues.email) {
      this.props.getAuthority();
    }
  }
    onSubmit = (values) => {
      const { initialValues } = this.props;
      this.props.setAuthority(values, initialValues.email);
    }
    render() {
      const { initialValues } = this.props;
      return (
        <Card>
          <CardBody className="p-0 p-md-2">
            <div>
              <h5 className="active">Hastane Yetkilisi Bilgileri</h5>
              <hr />
              <div className="fadeIn first my-3">
                <ExecutiveInfoForm onSubmit={this.onSubmit} initialValues={initialValues} />
              </div>
            </div>
          </CardBody>
        </Card>
      );
    }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setAuthority: (values, currentEmail) => {
      const newVal = {
        ...values,
      };
      if (newVal.email) {
        delete newVal.email;
      }
      dispatch(putAction('hospital/authority/change', newVal));
    },
    getAuthority() {
      dispatch(getAction('hospital/authority/current'));
    },
  };
};
const mapStateToProps = (state, ownProps) => ({
  initialValues: state.getHospitalAuthorityCurrent.data,
  hospitalAuthorityChange: state.hospitalAuthorityChange.success,
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
