/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/static-property-placement */
/* eslint-disable max-len */
/* eslint max-len: ["error", { "code": 300 }] */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
/* eslint-disable arrow-parens */
/* eslint-disable no-shadow */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable  react/forbid-prop-types */
/* eslint-disable  react/no-did-update-set-state */
/* eslint-disable  react/jsx-boolean-value */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import MaskedInput from 'react-text-mask';
import { rules } from '../validate';

const renderField = ({
  input, placeholder, type, mask, isDisabled, guide, showMask, keepCharPositions, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <MaskedInput {...input} guide={guide} placeholder={placeholder} disabled={isDisabled} type={type} mask={mask} showMask={showMask} keepCharPositions={keepCharPositions} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

class MaskedInputSection extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    mode: PropTypes.oneOf(['create', 'edit', 'view']).isRequired,
    initialValues: PropTypes.any,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    submessage: PropTypes.string,
    submessage2: PropTypes.string,
    mask: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
    ]).isRequired,
    validate: PropTypes.array,
    isDisabled: PropTypes.bool,
    guide: PropTypes.bool,
    showMask: PropTypes.bool,
    keepCharPositions: PropTypes.bool,
  };

  static defaultProps = {
    initialValues: undefined,
    validate: [],
    submessage: undefined,
    submessage2: undefined,
    isDisabled: false,
    guide: true,
    showMask: true,
    keepCharPositions: false,
  };

  render() {
    const { t, mode, initialValues, label, description, placeholder, name, type, mask, validate, submessage, submessage2, isDisabled, guide, showMask, keepCharPositions } = this.props;

    return (
      <div className="form__form-group">
        <span className="form__form-group-label">{label}
          {/* {mode !== 'view' && submessage && !submessage2 &&
            <span style={{ paddingLeft: '4px', color: t('tooltips.icon_color') }} data-tip={submessage} data-background-color={t('tooltips.background_color')} data-place="right" data-for={submessage}><i className={t('tooltips.class')} /><ReactTooltip id={submessage} /></span> }
          {mode !== 'view' && submessage && submessage2 &&
            <span style={{ paddingLeft: '4px', color: t('tooltips.icon_color') }} data-tip={`*${submessage} <br> *${submessage2}`} data-background-color={t('tooltips.background_color')} data-place="right" data-multiline="true" data-for={submessage2}><i className={t('tooltips.class')} /><ReactTooltip id={submessage2} /></span> } */}
        </span>
        {description &&
        <span className="form__form-group-description">
          {description}
        </span>}
        <div className="form__form-group-field">
          {mode !== 'view' ?
            <Field
              name={name}
              component={renderField}
              type={type}
              placeholder={placeholder}
              validate={validate && validate.map(rule => rules[rule])}
              mask={mask}
              isDisabled={isDisabled}
              guide={guide}
              showMask={showMask}
              keepCharPositions={keepCharPositions}
            />
            :
            (initialValues && initialValues !== undefined) && (initialValues[name] !== undefined ? initialValues[name] : <p>-</p>)}
        </div>
      </div>
    );
  }
}

export default MaskedInputSection;
